import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeProps as _normalizeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onFocus", "onClick", "onFocusout"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_HLPropWatcher = _resolveComponent("HLPropWatcher")!
  const _component_HLInputSelect = _resolveComponent("HLInputSelect")!

  return (_openBlock(), _createBlock(_component_HLInputSelect, {
    modelValue: _ctx.innerModel,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerModel) = $event)),
    options: _ctx.options,
    strictVModel: true
  }, {
    default: _withCtx(({model, isOpened, element, chosenOption, sortedOptions}) => [
      _createElementVNode("div", {
        ref: element,
        class: "relative"
      }, [
        _createElementVNode("div", _mergeProps({
          ref: "triggerRef",
          class: ["input input-select p-0 flex flex-row items-center flex-nowrap", {
          'input--valid': _ctx.valid,
          'input--invalid': !_ctx.valid,
          'input--disabled input-select--disabled': _ctx.disabled,
        }],
          [(_ctx.$test.field) || ""]: _ctx.field
        }, _ctx.$attrs, {
          tabindex: "0",
          onFocus: ($event: any) => (_ctx.withFocusIn($event, () => isOpened.value = true)),
          onClick: ($event: any) => (_ctx.withFocusIn($event, () => isOpened.value = true)),
          onFocusout: ($event: any) => (_ctx.withFocusOut($event, () => isOpened.value = false))
        }), [
          _createElementVNode("div", {
            class: _normalizeClass(["select-none input-padding flex-grow whitespace-nowrap overflow-hidden overflow-ellipsis", {
            'input-placeholder': !chosenOption,
          }])
          }, _toDisplayString(chosenOption?.label || _ctx.placeholder || '\xa0'), 3),
          (_ctx.clearable && !_ctx.disabled && !!chosenOption)
            ? (_openBlock(), _createElementBlock("div", _normalizeProps({
                key: 0,
                class: "px-2 self-stretch flex items-center cursor-pointer",
                [(_ctx.$test.fieldAction) || ""]: 'clear',
                onClick: _withModifiers(($event: any) => {isOpened.value = false; _ctx.innerModel = null;}, ["stop"])
              }), [
                _createVNode(_component_PhIcon, {
                  icon: "PhX",
                  weight: "bold"
                })
              ], 16, _hoisted_2))
            : _createCommentVNode("", true),
          (isOpened.value)
            ? (_openBlock(), _createElementBlock("div", _normalizeProps({
                key: 1,
                class: "px-3 self-stretch flex items-center cursor-pointer",
                [(_ctx.$test.fieldAction) || ""]: 'close',
                onClick: _withModifiers(($event: any) => (isOpened.value = false), ["stop"])
              }), [
                _createVNode(_component_PhIcon, {
                  icon: "PhCaretRight",
                  weight: "bold",
                  class: "transform -rotate-90"
                })
              ], 16, _hoisted_3))
            : (_openBlock(), _createElementBlock("div", _normalizeProps({
                key: 2,
                class: "px-3 self-stretch flex items-center cursor-pointer",
                [(_ctx.$test.fieldAction) || ""]: 'open',
                onClick: _withModifiers(($event: any) => (isOpened.value = true), ["stop"])
              }), [
                _createVNode(_component_PhIcon, {
                  icon: "PhCaretRight",
                  weight: "bold",
                  class: "transform rotate-90"
                })
              ], 16, _hoisted_4))
        ], 16, _hoisted_1),
        _createVNode(_component_HLPropWatcher, {
          props: [isOpened],
          onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onIsOpenedUpdate($event)))
        }, {
          default: _withCtx(() => [
            (isOpened.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  ref: "dropdownRef",
                  class: _normalizeClass(["input-select-dropdown", {
            'input-select-dropdown--disabled': _ctx.computedDisabled,
          }])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortBy(sortedOptions), (sortedOption) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: sortedOption.id,
                      onClick: ($event: any) => (sortedOption.disabled ? null : model.value = sortedOption.id)
                    }, [
                      _createVNode(_component_Button, {
                        class: _normalizeClass(["input-select-option", {
                'input-select-option--selected': sortedOption.selected,
                'input-select-option--disabled': sortedOption.disabled,
              }]),
                        tabindex: "-1"
                      }, {
                        default: _withCtx(() => [
                          (sortedOption.icon)
                            ? (_openBlock(), _createBlock(_component_PhIcon, {
                                key: 0,
                                icon: sortedOption.icon,
                                size: sortedOption.iconSize,
                                title: sortedOption.label,
                                weight: "bold"
                              }, null, 8, ["icon", "size", "title"]))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(sortedOption.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ], 8, _hoisted_5))
                  }), 128))
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["props"])
      ], 512)
    ]),
    _: 1
  }, 8, ["modelValue", "options"]))
}