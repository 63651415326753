
import {HLDropdown} from '@ui/components/headless/Dropdown';
import {injectAsideContext} from '@ui/helpers/aside';
import {useAuthStore} from '@ui/modules/Auth/store/AuthStore';
import {map} from 'lodash-es';
import {computed, defineComponent} from 'vue';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'AsideUser',
  components: {HLDropdown},
  inheritAttrs: false,
  setup() {
    const authStore = useAuthStore();
    const {collapsed} = injectAsideContext();
    const router = useRouter();

    const user = computed(() => {
      return authStore.user.value;
    });

    const hasUser = computed(() => {
      return !!authStore.user.value;
    });

    const userFullName = computed(() => {
      return user.value.FullName;
    });

    const userInitials = computed(() => {
      return map(userFullName.value.split(' '), (part) => part.slice(0, 1)).join('');
    });

    const onLogout = async () => {
      await router.push({name: 'redirect-logout'});
    };

    return {
      collapsed,
      hasUser,
      userFullName,
      userInitials,
      onLogout,
    };
  },
});
