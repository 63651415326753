import apiV1AdminEnums from '@api/posMiddlewareCore/action/apiV1AdminEnums';
import PosEnumsDto from '@api/posMiddlewareCore/entity/PosEnumsDto';
import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';

export class EnumStore extends Store<{
  enums: PosEnumsDto,
}> {
  constructor() {
    super({
      enums: null,
    });
  }

  fetchEnums = action(async () => {
    this.state.enums = await apiV1AdminEnums();
  });

  enums = getter(() => this.state.enums);
}

const storeSymbol = Symbol();

export const configureEnumStore = createConfigureStore<typeof EnumStore>(storeSymbol);
export const useEnumStore = createUseStore(EnumStore, storeSymbol);
