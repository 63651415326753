import {getInitFlags, injectFieldContext} from '@designeo/vue-forms';
import {
  AsyncComponentLoader,
  defineAsyncComponent,
  PropType,
} from 'vue';
import AsyncInputStateError from './AsyncInputStateError.vue';
import AsyncInputStateLoading from './AsyncInputStateLoading.vue';

export const inputProps = {
  modelValue: {
    type: null,
    default: undefined,
  },
  strictVModel: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
  flags: {
    type: Object as PropType<ReturnType<typeof getInitFlags>>,
    required: false,
    default: () => {
      const context = injectFieldContext();
      return context?.flags ?? getInitFlags();
    },
  },
  disabled: {
    type: Boolean,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: null,
  },
};

export const inputEvents = ['update:modelValue'];


export const createAsyncInput = <L extends AsyncComponentLoader<any>>(
  loader: L,
  {
    delay = 50,
    timeout = 10000,
  } = {},
) => defineAsyncComponent({
  // the loader function
  loader,
  loadingComponent: AsyncInputStateLoading,
  delay,
  errorComponent: AsyncInputStateError,
  timeout,
});
