import NumberType from '@api/posMiddlewareCore/types/NumberType';
import StringType from '@api/posMiddlewareCore/types/StringType';

export default {
  id: {
    type: new NumberType(),
  },
  name: {
    type: new StringType(),
  },
};
