import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col gap-8"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "typo-subtitle-2" }
const _hoisted_6 = { class: "typo-body-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_HLPhIconFallback = _resolveComponent("HLPhIconFallback")!
  const _component_HLDropdown = _resolveComponent("HLDropdown")!

  return (_ctx.systems.length)
    ? (_openBlock(), _createBlock(_component_HLDropdown, {
        key: 0,
        placement: "right-end"
      }, {
        default: _withCtx(({
      triggerRef,
      dropdownRef,
      isActive,
      toggle,
    }) => [
          _createElementVNode("div", _mergeProps(_ctx.$attrs, {
            ref: (el) => triggerRef.value = el,
            class: {
        'justify-center': _ctx.collapsed
      }
          }), [
            _createElementVNode("div", {
              class: "text-center typo-body-1 text-gray-600 tracking-wide font-medium uppercase py-4 px-5 flex flex-row justify-between items-center cursor-pointer",
              onClick: toggle
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex flex-row flex-grow items-center gap-4", {
            'justify-center': _ctx.collapsed
          }])
              }, [
                _createElementVNode("div", null, [
                  _createVNode(_component_PhIcon, {
                    icon: "PhDotsNine",
                    size: '5xl'
                  })
                ]),
                (!_ctx.collapsed && !_ctx.collapsedAfterAnimation)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('layout.components.aside.menu.applications')), 1))
                  : _createCommentVNode("", true)
              ], 2),
              (!_ctx.collapsed)
                ? (_openBlock(), _createBlock(_component_PhIcon, {
                    key: 0,
                    icon: "PhCaretDown",
                    size: "lg",
                    class: _normalizeClass(["transform transition-all justify-self-end", {
            '-rotate-180 lg:-rotate-90': isActive.value
          }])
                  }, null, 8, ["class"]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_1),
            (isActive.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  ref: (el) => dropdownRef.value = el,
                  class: "lg:absolute bg-white rounded border border-gray-200 py-6 px-6 text-darkest-gray",
                  style: {"min-width":"250px"}
                }, [
                  (_ctx.systems.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.systems, (system, index) => {
                          return (_openBlock(), _createElementBlock("a", {
                            key: index,
                            class: "flex flex-row items-center gap-7 cursor-pointer",
                            target: "_blank",
                            href: system.baseUri
                          }, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_HLPhIconFallback, {
                                icon: system.icon,
                                fallbackIcon: "PhArrowSquareOut"
                              }, {
                                default: _withCtx(({icon}) => [
                                  _createVNode(_component_PhIcon, {
                                    icon: icon,
                                    size: 40
                                  }, null, 8, ["icon"])
                                ]),
                                _: 2
                              }, 1032, ["icon"])
                            ]),
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_5, _toDisplayString(system.displayName), 1),
                              _createElementVNode("div", _hoisted_6, _toDisplayString(system.subtitle), 1)
                            ])
                          ], 8, _hoisted_4))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 512))
              : _createCommentVNode("", true)
          ], 16)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}