import {getInitFlags} from '@designeo/vue-forms/src/index';
import {useDateTime} from '@ui/helpers/dateTime';
import {DateTime} from 'luxon';

export const isValidByFlags = (flags: ReturnType<typeof getInitFlags>) => {
  if (!flags.validated) {
    return true;
  }
  return flags.valid;
};

export const useInputDate = (format) => {
  const {formatDate} = useDateTime(format);

  const getToday = () => {
    return {
      isDisabled: false,
      date: formatDate(DateTime.now()),
    };
  };

  const getYesterday = () => {
    return {
      isDisabled: false,
      date: formatDate(DateTime.now().minus({days: 1})),
    };
  };

  const modelFormatToPlaceholder = (format) => ({
    'dd. MM. y': 'dd. mm. rrrr',
    'dd. MM. y HH:mm': 'dd. mm. rrrr hh:mm',
  })[format] ?? format;

  const modelFormatToIMaskPattern = (format) => ({
    'dd. MM. y': 'DD. MM. YYYY',
    'dd. MM. y HH:mm': 'DD. MM. YYYY hh:mm',
  })[format] ?? format;

  return {
    getToday,
    getYesterday,
    modelFormatToIMaskPattern,
    modelFormatToPlaceholder,
  };
};
