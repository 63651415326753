/* eslint-disable array-bracket-newline,array-element-newline */
import {Language} from '@ui/constants/language';
import {
  has,
  keys,
  reject,
  set,
  toPairs,
} from 'lodash-es';
import {App} from 'vue';
import {
  createI18n,
  I18n,
  PluralizationRulesMap,
} from 'vue-i18n';
import {setLocale} from 'yup';
import {
  MixedLocale,
  NumberLocale,
  StringLocale,
} from 'yup/lib/locale';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = import('./modules').ModuleSystem & {
  app: App<any>,
}

export type RegisterI18NContext = {
  i18n: I18n,
}

type Layer = {[key: string]: object | string}

export const createTbtTranslations = (translations, firstLang = 'cs') => {
  const languages = (
    firstLang ? [firstLang, ...reject(keys(translations), (t) => t === firstLang)] :
    keys(translations)
  );

  const sanitizedTranslations = {
    ...translations,
  };

  const sniffer = (currentPath, currentLang) => (layer: Layer) => {
    const otherLanguages = reject(languages, (l) => l === currentLang);

    for (const [key, value] of toPairs(layer)) {
      if (typeof value === 'string') {
        for (const otherLang of otherLanguages) {
          if (!has(sanitizedTranslations, [otherLang, ...(currentPath.slice(1)), key])) {
            set(
              sanitizedTranslations,
              [otherLang, ...(currentPath.slice(1)), key],
              value.replace(/(^| \||\|)(?: |)/gi, `$1[TBT: ${otherLang}] `),
            );
          }
        }
      } else {
        sniffer([...currentPath, key], currentLang)(<Layer>(layer[key]));
      }
    }
  };

  for (const lang of languages) {
    sniffer([lang], lang)(translations[lang]);
  }

  return sanitizedTranslations;
};

export function registerVueI18n(ctx: CoreRequiredContext) {
  const i18n = createI18n({
    legacy: false,
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    // locale: ctx.locale ?? Language.CS,
    locale: Language.CS,
    fallbackLocale: Language.CS,
    availableLocales: [
      Language.CS,
      // Language.EN,
      // Language.SK,
      // Language.DE,
    ],
    pluralizationRules: <PluralizationRulesMap>{
      cs: function(choice, choicesLength) {
        if (choicesLength === 2) {
          return choice ?
            choice > 1 ?
              1 :
              0 :
            1;
        }

        return Math.min(
          choice === 0 ? 0 :
          choice === 1 ? 1 :
          choice < 5 ? 2 : 3,
          choicesLength - 1,
        );
      },
    },
    messages: createTbtTranslations(ctx.getTranslations()),
  });

  ctx.app.config.globalProperties.i18nInstall = i18n;

  ctx.app.use(i18n);

  setLocale({ // custom validations have their keys specified as arg
    mixed: <MixedLocale>{
      default: 'validations.mixed.default',
      required: 'validations.mixed.required',
    },
    number: <NumberLocale>{
      min: 'validations.number.min',
      max: 'validations.number.max',
    },
    string: <StringLocale> {
      email: 'validations.string.email',
      required: 'validations.string.required',
      min: 'validations.string.min',
      max: 'validations.string.max',
    },
  });

  return {
    i18n,
  };
}
