import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:flex-row h-screen bg-light-gray" }
const _hoisted_2 = { class: "flex-grow flex flex-row justify-center h-screen max-h-screen overflow-hidden" }
const _hoisted_3 = { class: "w-full h-full px-5 py-7 overflow-y-scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Aside = _resolveComponent("Aside")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Aside, {
      class: "flex-shrink-0 shadow-sm",
      menu: $props.menu
    }, null, 8, ["menu"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view, { class: "mx-auto max-w-screen-2xl" })
      ])
    ])
  ]))
}