
import {isFunction} from 'lodash-es';
import {computed, defineComponent} from 'vue';
import {RouteLocation, useRouter} from 'vue-router';
import {gridCellProps} from './gridCell';

export default defineComponent({
  name: 'GridCellLink',
  props: {
    ...gridCellProps,
    to: {
      type: [Object, Function],
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const link = computed<RouteLocation & {href: string}>(() => {
      if (isFunction(props.to)) {
        return router.resolve(props.to(props.value, props.value));
      }

      return router.resolve({
        ...props.to,
        // @ts-ignore
        params: props.item.data.toJson(), // TODO: pass only desired params
      });
    });

    const isAccessible = computed(() => {
      return link.value.name !== 'unauthorized';
    });

    return {
      link,
      isAccessible,
    };
  },
});
