import {VoucherState} from '@api/posMiddlewareCore/constants/voucherState';

export default {
  cs: {
    voucher: {
      detail: {
        title: 'Poukázka - {voucherNumber}',
        tabs: {
          vouchers: {
            title: 'Historie',
          },
        },
      },
      grid: {
        title: 'Poukázky',
        filters: {
          voucherNumber: 'ID',
          createdDate: 'Datum',
          stateEnum: {
            allOptions: 'Všechny stavy',
          },
          emission: {
            code: 'Kód emise',
          },
        },
        actions: {
          create: {
            title: 'Nové poukázky',
          },
          adminView: {
            title: 'Administrátor - zobrazení poukázek',
          },
        },
        advancedFilters: {
          title: 'Advanced filters',
          placeholders: {
            name: 'Name placeholder',
          },
        },
      },
      search: {
        title: 'Helpdesk - Poukázky',
      },
      form: {
        create: {
          title: '{name}',
          validate: {
            action: 'Validovat',
            success: 'Ověření nových poukázek proběhlo v pořádku. Můžete je vytvořit.',
          },
          generate: {
            action: 'Generovat',
          },
        },
        search: {
          noVoucherFind: 'Poukázku nebyla nalezena. Musíte zadat celé její unikátní číslo.',
        },
        placeholders: {
          numbers: 'Vložte kódy nových poukázek',
          voucherNumber: 'Zadejte číslo poukázky',
        },
        common: {
          sections: {
            generateVouchers: 'Založení nových poukázek',
            search: 'Vyhledat poukázku',
          },
        },
      },
      fields: {
        numbers: 'Kódy poukázek',
        voucherNumber: 'ID',
        validFrom: 'Platnost od',
        validTill: 'Platnost do',
        state: 'Stav',
        stateEnum: {
          [VoucherState.readyForActivation]: 'K aktivaci',
          [VoucherState.readyForUse]: 'K použití',
          [VoucherState.reserved]: 'Rezervováno',
          [VoucherState.used]: 'Uplatněná',
          [VoucherState.expired]: 'Vypršená',
          [VoucherState.canceled]: 'Zrušená',
        },
        usedAt: 'Uplatněno',
        usedPlace: 'Místo uplatnění',
        issuer: 'Vydavatel',
        emission: {
          code: 'Kód emise',
          name: 'Název emise',
        },
        customerNumber: 'Věrnost ID',
        amount: 'Použitelná hodnota',
        promotionCode: 'Promoce',
        articleNumber: 'ID Artiklu',
        maxUsageCount: 'Max. počet použití',
        usageCount: 'Počet použití',
      },
      validations: {
        numbers: 'Vouchery s čísly {voucherNumbers} nejsou validní',
      },
    },
  },
};
