/* eslint-disable max-len */
export const getSemVersionRegExp = () => /(\d+)\.(\d+)\.(\d+)(?:\.(\d+))?(?:(?:(-.+\.)(\d+))|(?:(-?.*)))?/gi;

// eslint-disable-next-line no-useless-escape,max-len
export const getEmailRegExp = () => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getFileNameRegExp = () => /(.+)(?:\.)(?:\.?)(.+)$/gm;

const phoneRegExpByCode = {
  'cz': /^(\+420)[1-9][0-9]{2}[0-9]{3}[0-9]{3}$/,                     // +420728524271
  'sk': /^(\+421)[1-9][0-9]{2}[0-9]{3}[0-9]{3}$/,                     // +421728524271
  'pl': /^(\+48)[5-8]\d\d{3}\d{2}\d{2}$/,                             // +48501231212
  'at': /^(\+43)\d{1,4}\d{3,12}$/,                                    // +431234567890
  'de': /^(\+49)\d{9,11}$/,                                           // +491605556417, +493012345678, +496912345678, +4915223433333
  'ua': /^(\+38)0\d{9}$/,                                             // +380123456789
  'ch': /^(\+41)[1-9\s][0-9\s]{1,12}$/,                               // +41783268674
};

// eslint-disable-next-line no-useless-escape,max-len
export const getPhoneRegExp = () => new RegExp(
  [
    phoneRegExpByCode.cz.source,
    phoneRegExpByCode.sk.source,
    phoneRegExpByCode.pl.source,
    phoneRegExpByCode.at.source,
    phoneRegExpByCode.de.source,
    phoneRegExpByCode.ua.source,
    phoneRegExpByCode.ch.source,
  ].join('|'),
);
