
import {HLDropdown} from '@ui/components/headless/Dropdown';
import PhIcon from '@ui/components/PhIcon.vue';
import {injectAsideContext} from '@ui/helpers/aside';
import {MenuItemTypeGroup, MenuItemTypeItem} from '@ui/helpers/menu';
import {HLPhIconFallback} from '@ui/helpers/phIcons';
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import AsideMenuItem from './AsideMenuItem.vue';

export default defineComponent({
  name: 'AsideMenuGroup',
  components: {HLPhIconFallback, AsideMenuItem, PhIcon, HLDropdown},
  props: {
    group: {
      type: Object as PropType<MenuItemTypeGroup>,
      required: true,
    },
  },
  setup(props) {
    const {collapsed, collapsedAfterAnimation} = injectAsideContext();

    const firstItem = computed(() => props.group.items?.[0] as MenuItemTypeItem);

    return {
      collapsed,
      collapsedAfterAnimation,
      firstItem,
    };
  },
});
