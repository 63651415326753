

import {VoucherState} from '@api/posMiddlewareCore/constants/voucherState';
import VoucherDetailDto from '@api/posMiddlewareCore/entity/VoucherDetailDto';
import {default as VoucherStateDto} from '@api/posMiddlewareCore/entity/VoucherState';
import {useVoucherFilters} from '@middleware-ui/modules/Voucher/filters';
import {gridCellProps, GridCellProps} from '@ui/components/grid/gridCells/gridCell';
import {
  computed,
  defineComponent,
} from 'vue';


export default defineComponent({
  name: 'GridCellState',
  props: {
    ...gridCellProps,
  } as GridCellProps<VoucherStateDto, VoucherDetailDto>,
  setup(props) {
    const {voucherStateFormat} = useVoucherFilters();

    const state = computed(() => {
      switch (props.value?.value) {
        case VoucherState.readyForActivation: return {
          color: 'bg-gray',
        };
        case VoucherState.readyForUse: return {
          color: 'bg-green',
        };
        case VoucherState.reserved: return {
          color: 'bg-orange',
        };
        case VoucherState.used: return {
          color: 'bg-red',
        };
        case VoucherState.expired: return {
          color: 'bg-red',
        };
        case VoucherState.canceled: return {
          color: 'bg-red',
        };
        default: return {
          color: 'bg-gray',
        };
      }
    });

    const label = computed(() => {
      return voucherStateFormat(props.value);
    });

    return {
      state,
      label,
    };
  },
});
