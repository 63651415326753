import {PromotionState} from '@api/posMiddlewareCore/constants/promotionState';

export default {
  cs: {
    promotion: {
      detail: {
        title: 'Promoce',
      },
      grid: {
        title: 'Promoce',
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          code: 'Kód',
          isValid: 'Platnost',
          state: 'Stav',
        },
        advancedFilters: {
          title: 'Filtrovat promoce',
          search: 'Vyhledat',
          placeholders: {
            search: 'Zadejte název (min. 2 znaky)',
            isValid: 'Vyberte platnost',
            state: 'Vyberte stav',
          },
        },
        actions: {
          create: {
            title: 'Založit promoci',
          },
          bulkStateChange: {
            title: 'Změna stavu',
          },
          bulkDelete: {
            title: 'Mazání',
            confirm: 'Opravdu si přejete hromadně změnit stav?',
          },
          bulkExpiration: {
            title: 'Změna platnosti (statická)',
          },
          bulkExpirationRelative: {
            title: 'Změna platnosti (relativní)',
          },
          exportAndImport: {
            title: 'Export a import',
          },
          export: {
            title: 'Export',
          },
        },
      },
      form: {
        create: {
          title: 'Nová promoce',
        },
        update: {
          title: 'Editace promoce',
        },
        bulkStateChange: {
          title: 'Hromadná změna stavu',
        },
        bulkExpiration: {
          title: 'Hromadná změna platnosti (statická)',
        },
        bulkExpirationRelative: {
          title: 'Hromadná změna platnosti (relativní)',
          addDays: 'Relativně o dny (lze zadat se znaménkem mínus)',
        },
        saveGlobalTemplate: {
          success: 'Globální šablona byla uložena',
        },
        deleteGlobalTemplate: {
          success: 'Globální šablona byla smazána',
        },
        placeholders: {
          name: 'Zadejte název',
          state: 'Vyberte stav',
          addDays: 'Zadejte počet dní',
        },
      },
      fields: {
        name: 'Název',
        validFrom: 'Platnost od',
        validTill: 'Platnost do',
        state: 'Stav',
        stateEnum: {
          [PromotionState.draft]: 'Koncept',
          [PromotionState.activeForTest]: 'Aktivováno pro TEST',
          [PromotionState.activeForProd]: 'Aktivováno pro PROD',
          [PromotionState.archived]: 'Archivováno',
        },
        isActive: 'Platnost',
        isActiveEnum: {
          true: 'Platné',
          false: 'Neplatné',
        },
        editedByName: 'Upraveno',
        editedDate: 'Datum editace',
        edited: 'Upraveno',
        promotionScript: 'Definice promoce',
      },
      modalExportImport: {
        title: 'Promoce - Export a import',
        import: {
          title: 'Vymazat a importovat',
          description: 'Vyberte soubor ve formátu JSON, který obsahuje definice promocí a promočních košů.',
          warning: 'Všechny aktuální promoce a promoční koše budou vymazány a dojde k jejich nahrazení daty ze souboru.',
        },

        export: {
          title: '@:action.export',
          promotionAndPromotionLists: 'Promoce a promoční koše',
          description: 'Export všech promocí a promočních košů ve formátu JSON.',
        },
      },
    },
    promotions: {
      errors: {
        articleGroupDoesntExists: 'Promoční koš "{name}" neexistuje',
        internalNumberDoesntExists: 'Interní číslo "{internalNumber}" neexistuje',
        promoIdEndsWithDotAndNumber: 'Promo "{name}" končí tečkou a číslem',
        nProblemsFound: 'Nalezeno {n} problémů  | Nalezen {n} problém | Nalezeny {n} problémy | Nalezeno {n} problémů',
      },
    },
  },
};
