import {ImportState} from '@api/posMiddlewareCore/constants/importState';
import {ImportType} from '@api/posMiddlewareCore/constants/importType';

export default {
  cs: {
    import: {
      grid: {
        title: 'Dávkové importy',
        filters: {
          id: 'id',
          name: 'Název souboru',
          importState: 'Stav',
          type: 'Typ souboru',
          trigger: 'trigger',
          tag: 'Štítek',
          processedAt: 'Zpracováno',
          createdByName: 'createdByName',
          createdById: 'createdById',
          createdAt: 'Vytvořeno',
        },
        advancedFilters: {
          title: 'Filtrovat dávkové importy',
          search: 'Vyhledat',
          placeholders: {
            id: 'id',
            name: 'Zadejte název souboru',
            importState: 'Vyberte stav',
            type: 'Vyberte typ souboru',
            trigger: 'trigger',
            tag: 'Zadejte štítek',
          },
        },
        actions: {
          upload: {
            title: 'Nový soubor',
          },
        },
      },
      form: {
        upload: {
          title: 'Nahrát dávkový import',
        },
        placeholders: {
          file: 'Vyberte dávkový soubor',
          type: 'Vyberte typ souboru',
          tag: 'Zadejte štítek',
        },
      },
      fields: {
        file: 'Soubor',
        id: 'id',
        name: 'Název souboru',
        importState: 'Stav',
        importStateEnum: {
          [ImportState.error]: 'Chyba',
          [ImportState.processed]: 'Zpracováno',
        },
        type: 'Typ souboru',
        typeEnum: {
          [ImportType.articles]: 'PLU',
          [ImportType.historicArticles]: 'PLU historické',
          [ImportType.cashiersJson]: 'Pokladní (json)',
          [ImportType.cashiersCsv]: 'Pokladní (csv)',
          [ImportType.press]: 'Tiskoviny',
        },
        trigger: 'trigger',
        tag: 'Štítek',
        processedAt: 'Zpracováno',
        createdByName: 'createdByName',
        createdById: 'createdById',
        createdAt: 'Vytvořeno',
      },
      modalUploadErrorLogDetail: {
        title: 'Detail chyby souboru: {fileName}',
      },
    },
  },
};
