import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createBlock(_component_Notification, _normalizeProps({
    open: !!_ctx.coreStore.toastContent.value,
    [(_ctx.$test.notification) || ""]: 'toast',
    offsetNotificationContentY: '5vh',
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClose())),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClose()))
  }), {
    default: _withCtx(({leaving}) => [
      _createElementVNode("div", {
        ref: "toastRef",
        class: _normalizeClass(["p-4 rounded-lg bg-opacity-20 border-2 border-opacity-40 cursor-pointer relative animate-background typo-body-3", !leaving && _ctx.interactionLevelOptions.classes]),
        style: _normalizeStyle(!leaving && _ctx.interactionLevelOptions.styles)
      }, _toDisplayString(_ctx.content ?? '\xa0'), 7)
    ]),
    _: 1
  }, 16, ["open"]))
}