import StringType from '@api/posMiddlewareCore/types/StringType';

export default {
  name: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
};
