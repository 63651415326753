import VoucherDetailDtoIEnvelope from '@api/posMiddlewareCore/entity/VoucherDetailDtoIEnvelope';
import {useI18n} from 'vue-i18n';

export const useVoucherFilters = <E extends VoucherDetailDtoIEnvelope>() => {
  const i18n = useI18n();

  const voucherStateFormat = (state: E['data']['state']) => {
    return i18n.t(`voucher.fields.stateEnum.${state.value}`);
  };

  return {
    voucherStateFormat,
  };
};
