import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/shops',
    name: 'shops',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.shopList,
    },
  },
  {
    path: '/shop/:id',
    name: 'shop',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
    redirect: {name: 'shop-configurations'},
    children: [
      {
        path: 'configurations',
        name: 'shop-configurations',
        component: () => import('./Detail/tabs/TabConfigurations.vue'),
      },
    ],
  },
  {
    path: '/shop/update/:id',
    name: 'shop-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
  {
    path: '/shop/create',
    name: 'shop-create',
    component: () => import('./Form/CreatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.shopCreate,
    },
  },
] as RegisterRoutesOptions;
