import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/promotions',
    name: 'promotions',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.promotionList,
    },
  },
  {
    path: '/promotion/create',
    name: 'promotion-create',
    component: () => import('./Form/CreatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.promotionCreate,
    },
  },
  {
    path: '/promotion/update/:id',
    name: 'promotion-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
] as RegisterRoutesOptions;
