import {
  createConfigureStore,
  createUseStore,
  getter,
  Store,
  action,
} from '@designeo/vue-helpers';

export interface ICrashStore {
  active: boolean,
}

const createInitState = (data?: Partial<ICrashStore>): ICrashStore => ({
  active: false,
});

export class CrashStore extends Store<ICrashStore> {
  constructor() {
    super(createInitState());
  }

  setState = action((state: boolean) => {
    this.state.active = state;
  });

  active = getter(() => {
    return this.state.active;
  });
}

const storeIdentifier = 'CrashStore';

export const configureCrashStore = createConfigureStore<typeof CrashStore>(storeIdentifier);
export const useCrashStore = createUseStore(CrashStore, storeIdentifier);
