export function emitTestEvent(event: string, payload: any = {}) {
  if (payload?.isAxiosError) {
    payload = {
      ...JSON.parse(JSON.stringify(payload)),
      response: JSON.parse(JSON.stringify(payload.response)),
      isAxiosError: true,
    };
  }


  if (typeof window !== 'undefined') {
    const parts = event.split(':');
    if (parts.length > 1) {
      window.postMessage({name: parts[0], payload}, window.location.origin);
      window.postMessage({name: event, payload}, window.location.origin);
    } else {
      window.postMessage({name: event, payload}, window.location.origin);
    }
  }
}
