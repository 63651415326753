import {AxiosError} from 'axios';
import {
  reject,
  flow,
  isEmpty,
  map,
} from 'lodash-es';

export interface IError {
  code: string,
  instructions: string,
  title: string,
}

export const isAxiosErrorGuard = (e): e is AxiosError => {
  return !!e?.isAxiosError;
};

export class ParsedErrorResult implements IError {
  code: string;
  instructions: string;
  title: string;

  constructor({code, instructions, title}: IError) {
    this.code = code;
    this.instructions = instructions;
    this.title = title;
  }

  toString() {
    return flow([
      (info) => reject(info, isEmpty),
      (info) => info.join(' - '),
    ])([this.title, this.instructions]);
  }
}

export type ParseResult<T extends any> = {
  raw: T,
  parsed: ParsedErrorResult[],
  message: string,
}

const parseAxiosError = (e: AxiosError): ParseResult<AxiosError> => {
  const errors = e?.response?.data?.errors ?? e?.response?.data?.value?.errors ?? [e.message] ?? [];

  const parsed = map(errors, (error) => {
    if (typeof error === 'string') {
      return new ParsedErrorResult({
        code: null,
        title: error,
        instructions: null,
      });
    }

    return new ParsedErrorResult(error);
  });

  return {
    parsed,
    message: parsed.join('; '),
    raw: e,
  };
};

const parseError = (e: Error): ParseResult<Error> => {
  const parsed = [
    new ParsedErrorResult({
      code: null,
      title: e.message,
      instructions: null,
    }),
  ];

  return {
    parsed,
    message: parsed.join('; '),
    raw: e,
  };
};


export function useError() {
  const parseException = (e: any) => {
    if (isAxiosErrorGuard(e)) {
      return parseAxiosError(e);
    }

    return parseError(e);
  };

  return {
    parseException,
  };
}
