
import PhIcon from '@ui/components/PhIcon.vue';
import {injectAsideContext} from '@ui/helpers/aside';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'AsideCollapseToggle',
  components: {PhIcon},
  setup() {
    const {collapsed, hovered} = injectAsideContext();

    return {
      collapsed,
      hovered,
    };
  },
});
