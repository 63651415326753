export default {
  cs: {
    shop: {
      detail: {
        title: 'Prodejna #{code} - {name} ',
        tabs: {
          configurations: {
            title: 'Konfigurace',
            actions: {
              create: 'Založit konfiguraci',
            },
          },
        },
        actions: {
          showOnMap: 'Zobrazit na mapě',
          activate: {
            confirm: 'Opravdu chcete prodejnu aktivovat?',
          },
          deactivate: {
            confirm: 'Opravdu chcete prodejnu deaktivovat?',
          },
        },
      },
      grid: {
        title: 'Prodejny',
        actions: {
          create: {
            title: 'Založit',
          },
        },
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          isActive: 'Aktivní',
          isActiveEnum: {
            allOptions: 'Všechny stavy',
          },
        },
        advancedFilters: {
          title: 'Filtrovat prodejny',
          search: 'Vyhledat',
          state: 'Stav',
          placeholders: {
            search: 'Vyhledat (min. 2 znaky)',
          },
        },
      },
      form: {
        create: {
          title: 'Nová prodejna',
        },
        update: {
          title: 'Úprava prodejny',
        },
        common: {
          sections: {
            detailedInformation: 'Podrobné informace',
            locationInformation: 'Informace o poloze',
            addressBook: 'Adresář',
          },
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód prodejny',
          email: 'Zadejte email',
          phone: 'Zadejte telefonní číslo',
          street: 'Zadejte ulici',
          city: 'Zadejte město',
          zip: 'Zadejte PSČ',
          country: 'Zadejte zemi',
          lat: 'Zadejte zeměpisnou šířku',
          lon: 'Zadejte zeměpisnou délku',
        },
      },
      fields: {
        name: 'Název',
        code: 'Kód',
        sapCode: 'SAP kód prodejny',
        id: 'ID',
        address: 'Adresa',
        empty: 'Neuvedeno',
        email: 'Email',
        phone: 'Telefon',
        isActive: 'Aktivní',
        isActiveEnum: {
          true: 'Aktivní',
          false: 'Neaktivní',
        },
        lat: 'Zeměpisná šířka',
        lon: 'Zeměpisná délka',
        country: 'Země',
        city: 'Město',
        street: 'Ulice',
        zip: 'PSČ',
        createdDate: 'Datum vytvoření',
        editedDate: 'Datum editace',
        groupAddress: 'Informace o poloze',
        createdByName: 'Vytvořil',
        editedByName: 'Upravil',
        groupInfo: 'Podrobné informace',
        createNew: 'Založit prodejnu',
        state: 'Stav',
        messageAddressBooks: 'Adresář',
        edited: 'Upraveno',
        created: 'Vytvořeno',
      },
    },
  },
};
