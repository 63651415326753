
import {useAppMenu} from '@middleware-ui/helpers/menu';
import AppLayoutDefault from '@ui/layouts/appLayouts/AppLayoutDefault.vue';

export default {
  name: 'LayoutDefault',
  components: {
    AppLayoutDefault,
  },
  setup () {
    const {menu} = useAppMenu();

    return {
      menu,
    };
  },
};
