import {
  kebabCase,
} from 'lodash-es';
import {App} from 'vue';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>,
}

export type RegisterTestHelpersContext = {
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $test: any,
  }
}

export function registerTestHelpers(ctx: CoreRequiredContext) {
  ctx.app.use({
    install: (app) => {
      Object.defineProperty(app.config.globalProperties, '$test', {
        get() {
          return new Proxy({test: 'data-test'}, {
            get(target, prop) {
              const prim = Reflect.get(target, 'test');
              const value = prim[prop] ?? (typeof prop === 'symbol' ? prim[prop] : `data-test-${kebabCase(prop)}`);
              return typeof value === 'function' ? value.bind(prim) : value;
            },
          });
        },
      });
    },
  });

  ctx.app.use({
    install: (app) => {
      Object.defineProperty(app.config.globalProperties, '$log', {
        // eslint-disable-next-line no-console
        value: console.log,
      });
    },
  });

  return {};
}
