import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Theme = _resolveComponent("Theme")!
  const _component_ModalConfirm = _resolveComponent("ModalConfirm")!
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_NotificationToast = _resolveComponent("NotificationToast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, _normalizeProps({
      [(_ctx.$test.pageFullPath) || ""]: _ctx.$route.fullPath,
      [(_ctx.$test.pageName) || ""]: _ctx.$route.name
    }), null, 16),
    _createVNode(_component_Theme),
    _createVNode(_component_ModalConfirm),
    _createVNode(_component_AppLoader),
    _createVNode(_component_NotificationToast)
  ], 64))
}