import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_HLDropdown = _resolveComponent("HLDropdown")!

  return (_openBlock(), _createBlock(_component_HLDropdown, { placement: "right-end" }, {
    default: _withCtx(({
      triggerRef,
      dropdownRef,
      isActive,
      toggle,
    }) => [
      _createElementVNode("div", _mergeProps(_ctx.$attrs, {
        ref: (el) => triggerRef.value = el,
        class: {
        'justify-center': _ctx.collapsed
      }
      }), [
        _createElementVNode("div", {
          class: "text-center typo-body-1 text-gray-600 tracking-wide font-medium uppercase border-b border-light-gray py-4 px-5 flex flex-row justify-between items-center cursor-pointer",
          onClick: toggle
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex flex-row flex-grow items-center gap-4", {
            'justify-center': _ctx.collapsed
          }])
          }, [
            _createElementVNode("div", null, [
              (_ctx.dropdown.icon)
                ? (_openBlock(), _createBlock(_component_PhIcon, {
                    key: 0,
                    icon: _ctx.dropdown.icon,
                    size: '5xl'
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.collapsed && !_ctx.collapsedAfterAnimation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.dropdown.label), 1))
              : _createCommentVNode("", true)
          ], 2),
          (!_ctx.collapsed)
            ? (_openBlock(), _createBlock(_component_PhIcon, {
                key: 0,
                icon: "PhCaretRight",
                class: _normalizeClass(["transform transition-all justify-self-end", {
            'rotate-90': isActive.value
          }])
              }, null, 8, ["class"]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_1),
        (isActive.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref: (el) => dropdownRef.value = el,
              class: "absolute bg-white rounded border border-gray-200 py-6 px-6 text-darkest-gray",
              style: {"min-width":"250px"}
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dropdown.dropdown)))
            ], 512))
          : _createCommentVNode("", true)
      ], 16)
    ]),
    _: 1
  }))
}