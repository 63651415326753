import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, _normalizeProps({
    open: !!_ctx.coreStore.confirmContent.value,
    zIndex: _ctx.zIndex,
    [(_ctx.$test.modal) || ""]: 'confirm',
    onClose: _cache[0] || (_cache[0] = (event) => event ? _ctx.onConfirm() : _ctx.onReject())
  }), {
    body: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _normalizeProps(_guardReactiveProps(_ctx.componentOptions)), null, 16))
    ]),
    _: 1
  }, 16, ["open", "zIndex"]))
}