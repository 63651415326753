import {getMetaTagContent} from '@ui/helpers/dom';
import {initSentry} from '@ui/helpers/sentry';
import {App} from 'vue';
import {Router} from 'vue-router';

/**
 * Defines what this module needs
 */
type SentryRequiredContext = {
  app: App<any>,
  router: Router,
}

export function registerSentry(ctx: SentryRequiredContext) {
  initSentry({
    app: ctx.app,
    router: ctx.router,
    config: {
      dsn: getMetaTagContent('sentry-dsn'),
    },
  });

  return {};
}
