import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/point-of-sale-attributes',
    name: 'point-of-sale-attributes',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      // TODO add key to identity
      // aclKeyForAccess: AclKey.pointOfSaleAttributeList,
    },
  },
] as RegisterRoutesOptions;
