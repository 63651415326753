import { toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "typo-subtitle-2 pb-1.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HLDropdown = _resolveComponent("HLDropdown")!

  return (_openBlock(), _createBlock(_component_HLDropdown, { placement: "right-end" }, {
    default: _withCtx(({
      triggerRef,
      dropdownRef,
      isActive,
      toggle,
    }) => [
      (_ctx.hasUser)
        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 0 }, _ctx.$attrs, {
            ref: (el) => triggerRef.value = el,
            class: ["relative flex-shrink border-t border-light-gray py-5 px-5 flex flex-row gap-3 items-center", {
        'justify-center': _ctx.collapsed
      }]
          }), [
            _createElementVNode("div", _normalizeProps({
              class: "rounded-full bg-gray-600 uppercase text-white typo-subtitle-2 w-10 h-10 flex items-center justify-center tracking-tight cursor-pointer",
              [(_ctx.$test.user) || ""]: 'toggle',
              onClick: toggle
            }), _toDisplayString(_ctx.userInitials), 17, _hoisted_1),
            (!_ctx.collapsed)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "typo-subtitle-2 text-gray-600 cursor-pointer",
                  onClick: toggle
                }, _toDisplayString(_ctx.userFullName), 9, _hoisted_2))
              : _createCommentVNode("", true),
            (isActive.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  ref: (el) => dropdownRef.value = el,
                  class: "absolute bg-white rounded border border-gray-200 py-4 px-8 text-darkest-gray",
                  style: {"min-width":"250px"}
                }, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.userFullName), 1),
                  _createElementVNode("div", _normalizeProps({
                    class: "typo-body-2 cursor-pointer",
                    [(_ctx.$test.user) || ""]: 'logout',
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLogout && _ctx.onLogout(...args)))
                  }), _toDisplayString(_ctx.$t('layout.components.user.logout')), 17)
                ], 512))
              : _createCommentVNode("", true)
          ], 16))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}