import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full bg-basic bg-opacity-50 flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLoaderPulse = _resolveComponent("IconLoaderPulse")!

  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: "overflow-hidden relative",
    type: "button"
  }, _ctx.$attrs, {
    disabled: _ctx.innerLoading || _ctx.disabled,
    class: {
      'transition duration-200': !_ctx.disabled,
      'opacity-50': _ctx.innerLoading,
      'cursor-not-allowed': _ctx.disabled
    }
  }), [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.innerLoading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_IconLoaderPulse, { class: "w-6 h-6 text-center self-center text-white" })
        ]))
      : _createCommentVNode("", true)
  ], 16, _hoisted_1))
}