import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 border border-light-gray bg-light-gray cursor-pointer rounded py-2 px-1 shadow-sm", {
      'rotate-180': !_ctx.collapsed,
      'block': _ctx.hovered,
      'hidden': !_ctx.hovered
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
  }, [
    _createVNode(_component_PhIcon, {
      icon: "PhCaretRight",
      size: "lg"
    })
  ], 2))
}