
export default {
  cs: {
    promotionList: {
      detail: {
        title: 'Promoční koš',
        suitableArticles: {
          title: 'Zobrazit vyhovující artikly',
          grid: {
            filters: {
              search: 'Vyhledat (min. 2 znaky)',
            },
            columns: {
              text: 'Název',
              internalNumber: 'Interní číslo',
              gtin: 'GTIN',
            },
            advancedFilters: {
              title: 'Advanced filters',
              placeholders: {
                search: 'Vyhledat',
              },
            },
          },
        },
      },
      grid: {
        title: 'Promoční koše',
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          code: 'Kód',
        },
        actions: {
          create: {
            title: 'Založit promoční koš',
          },
          bulkDelete: {
            title: 'Hromadné mazání',
            confirm: 'Opravdu si přejete hromadně smazat vybrané záznamy?',
            success: 'Záznamy byly úspěšně smazány',
          },
        },
      },
      form: {
        create: {
          title: 'Nový promoční koš',
        },
        update: {
          title: 'Editace promočního koše',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
        },
      },
      fields: {
        id: 'Unikátní identifikátor',
        name: 'Název',
        code: 'Kód',
        editedDate: 'Datum poslední úpravy',
        edited: 'Upraveno',
        listDefinition: 'Definice seznamu',
      },
    },
  },
};
