import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsideMenuDropdown = _resolveComponent("AsideMenuDropdown")!
  const _component_AsideMenuGroup = _resolveComponent("AsideMenuGroup")!
  const _component_AsideMenuItem = _resolveComponent("AsideMenuItem")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (abstractItem, groupIndex) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: groupIndex }, [
      (abstractItem.isDropdown)
        ? (_openBlock(), _createBlock(_component_AsideMenuDropdown, {
            key: 0,
            dropdown: abstractItem.asDropdown
          }, null, 8, ["dropdown"]))
        : (abstractItem.isGroup)
          ? (_openBlock(), _createBlock(_component_AsideMenuGroup, {
              key: 1,
              group: abstractItem.asGroup
            }, null, 8, ["group"]))
          : (_openBlock(), _createBlock(_component_AsideMenuItem, {
              key: 2,
              item: abstractItem.asItem
            }, null, 8, ["item"]))
    ], 64))
  }), 128))
}