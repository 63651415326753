import {Menu} from '@ui/helpers/menu';
import {
  inject,
  provide,
  Ref,
  ComputedRef,
} from 'vue';

const AsideContext = 'AsideContext';

type AsideApi = {
  hovered: Ref<boolean>,
  collapsed: Ref<boolean>,
  collapsedAfterAnimation: Ref<boolean>,
  collapse: () => void,
  rise: () => void,
  menu: ComputedRef<Menu>,
}

export const injectAsideContext = () => {
  return <AsideApi>inject(AsideContext);
};

export const provideAsideContext = ({
  collapsed,
  hovered,
  collapsedAfterAnimation,
  menu,
}: {
  collapsed: Ref<boolean>,
  hovered: Ref<boolean>,
  collapsedAfterAnimation: Ref<boolean>,
  menu: ComputedRef<Menu>,
}) => {
  const context: AsideApi = {
    hovered,
    collapsed,
    collapsedAfterAnimation,
    menu,
    collapse() {
      collapsed.value;
    },
    rise() {
      collapsed.value;
    },
  };

  provide(AsideContext, context);
};
