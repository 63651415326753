import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_HLAsyncButton = _resolveComponent("HLAsyncButton")!

  return (_openBlock(), _createBlock(_component_HLAsyncButton, { onClick: _ctx.onClick }, {
    default: _withCtx(({invokeOnClick, isInternallyWaiting}) => [
      _createVNode(_component_ButtonBase, _mergeProps(_ctx.$attrs, {
        loading: _ctx.loading ?? isInternallyWaiting,
        disabled: _ctx.disabled,
        onClick: invokeOnClick
      }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 2
      }, 1040, ["loading", "disabled", "onClick"])
    ]),
    _: 3
  }, 8, ["onClick"]))
}