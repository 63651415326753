import {
  inject,
  provide,
  Ref,
} from 'vue';

const DropdownContext = 'DropdownContext';

type DropdownApi = {
  isActive: Ref<boolean>,
  show: () => void,
  hide: () => void,
  toggle: () => void,
}

export const injectDropdownContext = () => {
  return <DropdownApi>inject(DropdownContext);
};

export const provideDropdownContext = ({
  isActive,
  show,
  hide,
  toggle,
}: {
  isActive: Ref<boolean>,
  show: () => void,
  hide: () => void,
  toggle: () => void,
}) => {
  const context: DropdownApi = {
    isActive,
    show,
    hide,
    toggle,
  };

  provide(DropdownContext, context);
};
