import {createStartup} from '@ui/modules/Startup/createStartup';
import {
  App,
} from 'vue';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>,
  defaultLayout: any,
}

export type RegisterTestHelpersContext = {
}

export async function registerStartup(ctx: CoreRequiredContext) {
  let startup = await createStartup(ctx.defaultLayout);
  startup.mount('#startup');

  let closed = false;

  const ensureStartupOpen = async () => {
    if (!closed) {
      ensureStartupClose();
    }

    startup = await createStartup(ctx.defaultLayout);
    startup.mount('#startup');
    closed = false;
  };

  const ensureStartupClose = () => {
    if (!closed) {
      closed = true;
      startup.unmount();
    }
  };

  return {
    ensureStartupOpen,
    ensureStartupClose,
  };
}
