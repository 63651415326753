
// @ts-ignore
import mainLogo from '@ui/assets/images/logo.svg';
import IconThreeDots from '@ui/components/ThreeDots.vue';
import ModalCrashState from '@ui/modules/Core/CrashState/ModalCrashState.vue';
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'StartupPage',
  components: {
    ModalCrashState,
    IconThreeDots,
  },
  setup(props) {
    return {
      mainLogo,
    };
  },
});
