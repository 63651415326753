
import PhIcon from '@ui/components/PhIcon.vue';
import {injectAsideContext} from '@ui/helpers/aside';
import {MenuItemTypeItem} from '@ui/helpers/menu';
import {defineComponent, PropType} from 'vue';

export default defineComponent({
  name: 'AsideMenuItem',
  components: {PhIcon},
  props: {
    item: {
      type: Object as PropType<MenuItemTypeItem>,
      required: true,
    },
    isSubitem: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {collapsed, collapsedAfterAnimation} = injectAsideContext();

    return {
      collapsed,
      collapsedAfterAnimation,
    };
  },
});
