import {createContext} from '@designeo/js-helpers/src/index';
import {registerCoreModule} from '@ui/modules/Core';
import {registerVueI18n} from '@ui/plugins/i18n';
import {registerModuleSystem} from '@ui/plugins/modules';
import {registerTestHelpers} from '@ui/plugins/testHelpers';
import {createApp} from 'vue';
import StartupPage from './Startup/StartupPage.vue';

export async function createStartup(defaultLayout) {
  const app = createApp(StartupPage);

  const ctx = createContext({
    app,
    appId: {},
    defaultLayout,
  })
    .register(registerTestHelpers)
    .register(registerModuleSystem)
    .register(registerCoreModule)
    .register(registerVueI18n);

  const resolvedCtx = await ctx.resolve();

  return resolvedCtx.app;
}
