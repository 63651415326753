export default {
  cs: {
    pointOfSale: {
      detail: {
        title: 'POS #{shopCodeAndPosCode}',
        tabs: {
          overview: {
            title: 'Přehled pokladny',
            isOnline: {
              title: 'Stav',
              onlineSince: 'ONLINE',
              offlineSince: 'OFFLINE',
            },
            posVersion: {
              title: 'Verze POS',
            },
            synchronizations: {
              title: 'Synchronizace',
              lastUpdate: 'Poslední aktualizace: {lastUpdate}',
            },
            configurations: {
              title: 'Konfigurace',
              settingsFromAttributes: 'Nastavení přebíráno z atributů',
              version: 'Verze {version}',
            },
            attributes: {
              title: 'Atributy',
              noAttributes: 'Nejsou přiřazeny žádné atributy',
            },
          },
          synchronizations: {
            title: 'Synchronizace',
          },
          versions: {
            title: 'Verze',
          },
          configurations: {
            title: 'Konfigurace',
            actions: {
              create: 'Založit konfiguraci',
            },
          },
          transactions: {
            title: 'Transakce',
          },
        },
        actions: {
          activate: {
            confirm: 'Opravdu chcete pokladnu aktivovat?',
          },
          deactivate: {
            confirm: 'Opravdu chcete pokladnu deaktivovat?',
          },
          assignAttributes: {
            title: 'Upravit POS atributy',
          },
          fourEyesCode: {
            title: 'Kód pro 4 oči',
          },
          generateNewKey: {
            title: 'Vygenerovat nový klíč',
            confirm: 'Opravdu si přejete vygenerovat nový registrační klíč?',
          },
        },
      },
      grid: {
        title: 'Pokladny',
        gridCells: {
          posVersion: {
            version: 'Verze: {version}',
            type: 'Typ: {type}',
            condition: 'Podmínka: {condition}',
            validFrom: 'Aktualizace: {validFrom}',
            description: '{posVersion}, {type} (podmínka: {condition}), {validFrom}',
          },
        },
        actions: {
          create: {
            title: 'Založit',
          },
          attributeAssign: {
            title: 'Přiřadit atributy',
          },
          attributeRemove: {
            title: 'Odebrat atributy',
          },
          versionChange: {
            title: 'Aktualizovat',
          },
          shopDetail: {
            title: 'Detail prodejny',
          },
        },
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          shop: 'Prodejna',
          attributes: 'Atribut',
          everythingSyncedWarning: 'Stav synchronizace',
          isOnline: 'Online/Offline',
          isActive: 'Stav',
          currentRealVersion: 'Verze',
          posVersionFilter: 'Aktualizace',
        },
        advancedFilters: {
          title: 'Filtrovat pokladny',
          search: 'Vyhledat',
          placeholders: {
            search: 'Zadejte název (min. 2 znaky)',
            shop: 'Vyberte prodejnu',
            attributes: 'Vyberte atribut',
            everythingSyncedWarning: 'Vyberte stav synchronizace',
            isOnline: 'Vyberte stav',
            isActive: 'Vyberte stav',
            currentRealVersion: 'Vyberte verzi',
            posVersionFilter: 'Vyberte aktualizaci',
          },
        },
      },
      form: {
        create: {
          title: 'Nová pokladna',
        },
        update: {
          title: 'Úprava pokladny',
        },
        attributeAssign: {
          title: 'Hromadné přiřazení atributů',
        },
        attributeRemove: {
          title: 'Hromadné odebrání atributů',
        },
        fourEyesCode: {
          title: 'Kód pro 4 oči',
        },
        versionChange: {
          title: 'Hromadná aktualizace verze',
        },
        common: {
          sections: {},
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
          eetIpAddress: 'Zadejte ip adresu EET',
          shop: 'Vyberte prodejnu',
          personalNumber: 'Zadejte číslo pokladního',
        },
      },
      fields: {
        name: 'POS',
        code: 'Kód',
        shop: 'Prodejna',
        shopCode: '#',
        attributes: 'Atributy',
        currentRealVersion: 'Verze POS',
        posVersion: 'Aktualizace',
        everythingSyncedWarning: 'Sync',
        everythingSyncedWarningEnum: {
          true: 'Chybné synchronizace',
          false: 'Kompletní synchronizace',
        },
        isOnline: 'Online',
        isOnlineEnum: {
          true: 'Online',
          false: 'Offline',
        },
        isActive: 'Aktivní',
        isActiveEnum: {
          true: 'Aktivní',
          false: 'Neaktivní',
        },
        singleUseCode: 'Jednorázový klíč',
        currentRealSchemaVersion: 'Verze schématu',
        createdDate: 'Datum vytvoření',
        editedDate: 'Datum editace',
        eetIpAddress: 'IP Adresa EET',
        personalNumber: 'Číslo pokladního',
        edited: 'Upraveno',
        created: 'Vytvořeno',
      },
    },
  },
};
