import {createPersistentVariable} from '@designeo/js-helpers';
import {
  Persister,
  PersisterState,
  RestoredPersisterState,
} from '@designeo/vue-grid';
import {useAuthStore} from '@ui/modules/Auth/store/AuthStore';
import {useRouter} from 'vue-router';

export const sessionStoragePersistentVariable = createPersistentVariable({localStorage: globalThis['sessionStorage']});

const gridPersister = sessionStoragePersistentVariable('gridStates', {});

export const createGridSessionStoragePersister = (key) => {
  if (!key) {
    throw new Error('Key can not be empty!');
  }

  const authStore = useAuthStore();

  const userKey = `${key}:${authStore.user.value.tokenData.name}`;

  return <Persister>{
    persist(state: PersisterState) {
      gridPersister.set({
        ...gridPersister.get(),
        [userKey]: state,
      });
    },
    restore(): RestoredPersisterState {
      return <RestoredPersisterState>{
        ...(gridPersister.get()?.[userKey] ?? {}),
        restored: true,
      };
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetSessionStoragePersister = () => {
  gridPersister.set({});
};


export const createGridUrlSearchParams = (gridName, state: Partial<PersisterState>) => {
  const searchParams = new URLSearchParams({});
  searchParams.set('state', JSON.stringify(state));

  return {
    [`grid:${gridName}`]: searchParams.toString(),
  };
};


export const createGridUrlPersister = (key) => {
  const router = useRouter();

  return {
    persist(state: PersisterState) {
      router.replace({
        query: {
          ...router.currentRoute.value.query ?? {},
          ...createGridUrlSearchParams(key, state),
        },
      });
    },
    restore(): RestoredPersisterState {
      const searchParams = new URLSearchParams(router.currentRoute.value.query?.[`grid:${key}`] as string ?? {});

      return {
        ...JSON.parse(searchParams.get('state')),
        restored: true,
      } as RestoredPersisterState;
    },
  };
};
