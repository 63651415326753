import {getEmailRegExp, getPhoneRegExp} from '@ui/helpers/regExp';
import {uniq} from 'lodash-es';
import {toRaw} from 'vue';
import {useI18n} from 'vue-i18n';
import * as yup from 'yup';

export function initYup() {
  yup.addMethod(yup.string, 'email', function validateEmail(message) {
    return this.matches(getEmailRegExp(), {
      message,
      name: 'email',
      excludeEmptyString: true,
    });
  });

  /**
   * TODO: Refactor to BE validation from api!
   */
  yup.addMethod(yup.string, 'phone', function validateEmail(message) {
    return this.matches(getPhoneRegExp(), {
      message,
      name: 'phone',
      excludeEmptyString: true,
    });
  });
}

export function injectDependencies<
  TCast extends any,
  TContext
>(dependencies: string[]): yup.TestFunction<TCast, TContext> {
  return function (this: yup.TestContext<TContext>, value, context) {
    const currentDependencies = [...(context.schema.deps ?? [])];
    context.schema.deps = uniq([...currentDependencies, ...dependencies]);
    return true;
  };
}

export function useValidations() {
  const {invalidTranslation, invalidJsonTranslation} = useValidationTranslations();

  const jsonValidation = <TCast extends string, TContext>(field): yup.TestFunction<TCast, TContext> => {
    return function (this: yup.TestContext<TContext>, value, context) {
      try {
        JSON.parse(value);
        return true;
      } catch (e) {
        return context.createError({
          message: invalidJsonTranslation(field),
        });
      }
    };
  };

  const jsonSchemaValidation = <TCast, TContext>(field, getJsonSchema): yup.TestFunction<TCast, TContext> => {
    const schemaMap = new Map();

    const validateConfiguration = async (schema, value) => {
      const SchemaValidator = (await import('@ui/helpers/ajv')).SchemaValidator;

      let validator;

      if (schemaMap.has(schema)) {
        validator = schemaMap.get(schema);
      } else {
        validator = new SchemaValidator(schema);
        schemaMap.set(schema, validator);
      }

      return validator.validate(value) as {
        isValid: boolean,
        errors: any[],
      };
    };

    return async function (this: yup.TestContext<TContext>, value, context) {
      if (!value) {
        return true;
      }

      if (typeof value === 'string') {
        const jsonValidationResult = jsonValidation.call(this, field)(value, context);

        if (jsonValidationResult instanceof yup.ValidationError) {
          return jsonValidationResult;
        }
      }

      const result = await validateConfiguration(toRaw(getJsonSchema()), value);

      if (result.isValid) {
        return true;
      }

      return context.createError({
        message: result.errors[0] ?? invalidTranslation(field),
      });
    };
  };

  return {
    jsonValidation,
    jsonSchemaValidation,
  };
}

export function useValidationTranslations() {
  const i18n = useI18n();

  const requiredTranslation = (field) => {
    return i18n.t('validation.required', {field});
  };

  const integerTranslation = () => {
    return i18n.t('validation.integer');
  };

  const positiveNumberTranslation = () => {
    return i18n.t('validation.positiveNumber');
  };

  const dateFormatTranslation = () => {
    return i18n.t('validation.invalidDateForm');
  };

  const dateMinTranslation = (min) => {
    return i18n.t('validation.dateMin', {min});
  };

  const dateMaxTranslation = (max) => {
    return i18n.t('validation.dateMax', {max});
  };

  const stringMinTranslation = (min) => {
    return i18n.t('validation.stringMin', {min});
  };

  const stringMaxTranslation = (max) => {
    return i18n.t('validation.stringMax', {max});
  };

  const invalidTranslation = (field) => {
    return i18n.t('validation.invalid', {field});
  };

  const invalidJsonTranslation = (field) => {
    return i18n.t('validation.invalidJson', {field});
  };

  const emailTranslation = (field) => {
    return i18n.t('validation.email', {field});
  };

  const phoneTranslation = (field) => {
    return i18n.t('validation.phone', {field});
  };
  const requiredFileTranslation = () => {
    return i18n.t('validation.file.required');
  };

  return {
    requiredTranslation,
    integerTranslation,
    positiveNumberTranslation,
    requiredFileTranslation,
    dateFormatTranslation,
    dateMinTranslation,
    dateMaxTranslation,
    stringMinTranslation,
    stringMaxTranslation,
    invalidTranslation,
    invalidJsonTranslation,
    emailTranslation,
    phoneTranslation,
  };
}
