
import {ZLayer} from '@ui/constants/zLayer';
import {emitTestEvent} from '@ui/helpers/testEvent';
import {useCoreStore} from '@ui/modules/Core/store/CoreStore';
import {INotification} from '@ui/modules/Core/types';
import {TestEvent} from '@ui/tests/e2e/helpers/testEvents';
import {
  defineComponent,
  onBeforeUnmount,
  ref,
  Ref,
  watch,
  computed,
} from 'vue';
import TransitionWrapper from '../TransitionWrapper.vue';

export default defineComponent({
  components: {
    TransitionWrapper,
  },
  inheritAttrs: false,
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    zIndex: {
      type: Number,
      default: ZLayer.notification,
    },

    silenceCloseListeners: {
      type: Boolean,
      required: false,
      default: false,
    },

    offsetNotificationContentX: {
      type: String,
      required: false,
      default: null,
    },
    offsetNotificationContentY: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['close', 'click'],
  setup(props, {emit}) {
    const coreStore = useCoreStore();
    const enterTime = ref(300);
    const leaveTime = ref(200);
    const isOpened = ref(props.open);

    const notification: Ref<INotification> = ref(null);
    const body = ref(null);

    const onClose = () => {
      if (notification.value) {
        emit('close');
      }
    };

    const onOpen = () => {
      notification.value = coreStore.requestNotification(onClose, {
        zIndexBase: props.zIndex,
        silenceCloseListeners: props.silenceCloseListeners,
      });
      // @ts-ignore
      document.activeElement.blur();
    };

    watch(() => props.open, (newVal) => {
      if (props.open && isOpened.value) {
        emitTestEvent(TestEvent.notificationClosed);
      } else if (!props.open && !isOpened.value) {
        emitTestEvent(TestEvent.notificationOpened);
      }

      if (newVal && !notification.value) {
        onOpen();
      } else if (!newVal && notification.value) {
        coreStore.removeModal(notification.value.id);
        notification.value = null;
      }
    }, {immediate: true});

    function notificationOpened() {
      isOpened.value = props.open;
      emitTestEvent(TestEvent.notificationOpened);
    }

    function notificationClosed() {
      isOpened.value = props.open;
      emitTestEvent(TestEvent.notificationClosed);
    }

    onBeforeUnmount(() => {
      try {
        if (notification.value) {
          coreStore.removeNotification(notification.value.id);
        }
      } catch (err) {
        console.error(err);
      }
    });

    const totalZIndex = computed(() => {
      return notification.value?.zIndexBase + notification.value?.zIndexOffset ?? 0;
    });

    return {
      enterTime,
      leaveTime,
      notificationOpened,
      notificationClosed,
      onClose,
      totalZIndex,
      body,
    };
  },
});
