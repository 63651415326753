
import LoaderPulse from '@ui/components/LoaderPulse.vue';
import TransitionWrapper from '@ui/components/TransitionWrapper.vue';
import {ZLayer} from '@ui/constants/zLayer';
import {computed, defineComponent} from 'vue';
import {useCoreStore} from '../store/CoreStore';

export default defineComponent({
  name: 'AppLoader',
  components: {
    LoaderPulse,
    TransitionWrapper,
  },
  setup() {
    const coreStore = useCoreStore();

    const loaderActive = computed(() => {
      return coreStore.loaderActive.value;
    });

    const loaderOpacity = computed(() => {
      return coreStore.loaderOpacity.value;
    });

    return {
      ZLayer,
      loaderActive,
      loaderOpacity,
    };
  },
});
