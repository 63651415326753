export enum PointOfSaleUpdateStatusType {
  archivedByRegistration = 'ArchivedByRegistration',
  planned = 'Planned',
  synced = 'Synced',
  waitingForCapacity = 'WaitingForCapacity',
  downloading = 'Downloading',
  downloaded = 'Downloaded',
  cancelPlanned = 'CancelPlanned',
  cancelled = 'Cancelled',
  updating = 'Updating',
  updated = 'Updated',
  updateFailed = 'UpdateFailed',
  downloadFailed = 'DownloadFailed',
  updateNotStarted = 'UpdateNotStarted'
}
