import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["onUpdate:modelValue", "placeholder", "disabled", "readonly"]
const _hoisted_3 = { class: "input-warning" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HLInputText = _resolveComponent("HLInputText")!

  return (_openBlock(), _createBlock(_component_HLInputText, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    strictVModel: _ctx.strictVModel
  }, {
    default: _withCtx(({ model: hlModel }) => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", _mergeProps({
          ref: "inputRef",
          "onUpdate:modelValue": ($event: any) => ((hlModel.value) = $event),
          type: "text",
          placeholder: _ctx.placeholder,
          disabled: _ctx.computedDisabled,
          readonly: _ctx.computedReadonly,
          class: ["input", [
          {
            'input--valid': _ctx.valid,
            'input--invalid': !_ctx.valid,
            'input--disabled': _ctx.disabled,
          }
        ]],
          [(_ctx.$test.field) || ""]: _ctx.field
        }, _ctx.$attrs), null, 16, _hoisted_2), [
          [_vModelText, hlModel.value]
        ]),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_3, " ! ", 512), [
              [_vShow, !_ctx.valid]
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "strictVModel"]))
}