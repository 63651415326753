import {isDate, isString} from 'lodash-es';
import {DateTime} from 'luxon';

type DateValue = DateTime|Date|String

export const nowIsBetweenDates = (dateFrom: Date, dateTo: Date, {fallback = true} = {}) => {
  const now = new Date();

  if (dateFrom && dateTo) {
    return dateFrom <= now && now <= dateTo;
  }

  if (!dateTo && !dateFrom) {
    return fallback;
  }

  if (!dateTo) {
    return dateFrom <= now;
  }

  if (!dateFrom) {
    return now <= dateTo;
  }

  return false;
};

export const useDateTime = (format) => {
  const toDateTime = (value: DateValue) => {
    if (isDate(value)) {
      return DateTime.fromJSDate(value);
    } else if (isString(value)) {
      return DateTime.fromFormat(value, format);
    }

    return value as DateTime;
  };
  const formatDate = (value: DateValue) => {
    return toDateTime(value)?.toFormat(format);
  };
  const toJSDate = (value: DateValue) => {
    return toDateTime(value)?.toJSDate();
  };

  return {
    toDateTime,
    formatDate,
    toJSDate,
  };
};
