
import {Menu} from '@ui/helpers/menu';
import Aside from '@ui/layouts/components/Aside.vue';
import {PropType} from 'vue';

export default {
  name: 'AppLayoutDefault',
  components: {Aside},
  props: {
    menu: {
      type: Object as PropType<Menu>,
      required: true,
    },
  },
};
