import {map} from 'lodash-es';
import {
  defineComponent,
  h,
  PropType,
  Ref,
  ReactiveEffect,
  watch,
} from 'vue';

export const HLPropWatcher = defineComponent({
  name: 'HLPropWatcher',
  props: {
    props: {
      type: Array as PropType<Array<Ref | ReactiveEffect>>,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, {emit}) {
    watch(props.props, (value) => {
      emit('update', value);
    }, {immediate: true});

    return {};
  },
  render() {
    const slotData = {};

    return map((this.$slots?.default?.(slotData) ?? []), (slot) => h(slot));
  },
});
