import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center", {
      'justify-between py-4 flex-row gap-8': !_ctx.single,
      'justify-end': _ctx.single,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}