import {ActionListType} from '@api/posMiddlewareCore/ActionListType';

export enum AclKey {
  cashierList = 'cashierList',
  cashierCreate = 'cashierCreate',
  cashierGet = 'cashierGet',
  cashierUpdate = 'cashierUpdate',
  cashierPinChange = 'cashierPinChange',
  cashierActivate = 'cashierActivate',
  cashierDeactivate = 'cashierDeactivate',

  pointOfSaleList = 'pointOfSaleList',
  pointOfSaleCreate = 'pointOfSaleCreate',
  pointOfSaleGet = 'pointOfSaleGet',
  pointOfSaleUpdate = 'pointOfSaleUpdate',
  pointOfSaleActivate = 'pointOfSaleActivate',
  pointOfSaleDeactivate = 'pointOfSaleDeactivate',

  pointOfSaleConfigurationCreate = 'pointOfSaleConfigurationCreate',
  pointOfSaleConfigurationGet = 'pointOfSaleConfigurationGet',
  pointOfSaleConfigurationUpdate = 'pointOfSaleConfigurationUpdate',

  shopList = 'shopList',
  shopCreate = 'shopCreate',
  shopGet = 'shopGet',
  shopUpdate = 'shopUpdate',
  shopActivate = 'shopActivate',
  shopDeactivate = 'shopDeactivate',

  shopConfigurationCreate = 'shopConfigurationCreate',
  shopConfigurationGet = 'shopConfigurationGet',
  shopConfigurationUpdate = 'shopConfigurationUpdate',

  configurationList = 'configurationList',
  configurationCreate = 'configurationCreate',
  configurationGet = 'configurationGet',
  configurationUpdate = 'configurationUpdate',

  pointOfSaleAttributeList = 'pointOfSaleAttributeList',
  pointOfSaleAttributeCreate = 'pointOfSaleAttributeCreate',
  pointOfSaleAttributeGet = 'pointOfSaleAttributeGet',
  pointOfSaleAttributeUpdate = 'pointOfSaleAttributeUpdate',
  pointOfSaleBulkAttributeAssign = 'pointOfSaleBulkAttributeAssign',
  pointOfSaleBulkVersionChange = 'pointOfSaleBulkVersionChange',

  importList = 'importList',
  importUpload = 'importUpload',
  importGet = 'importGet',
  importDownload = 'importDownload',

  promotionList = 'promotionList',
  promotionCreate = 'promotionCreate',
  promotionGet = 'promotionGet',
  promotionUpdate = 'promotionUpdate',
  promotionBulkDelete = 'promotionBulkDelete',
  promotionChangeBulkState = 'promotionChangeBulkState',
  promotionClearAndImport = 'promotionClearAndImport',
  promotionChangeBulkExpiration = 'promotionChangeBulkExpiration',
  promotionChangeBulkExpirationRelative = 'promotionChangeBulkExpirationRelative',
  promotionChangeBulkExpirationAll = 'promotionChangeBulkExpirationAll',
  promotionChangeBulkExpirationRelativeAll = 'promotionChangeBulkExpirationRelativeAll',

  promotionListList = 'promotionListList',
  promotionListCreate = 'promotionListCreate',
  promotionListGet = 'promotionListGet',
  promotionListUpdate = 'promotionListUpdate',
  promotionListBulkDelete = 'promotionListBulkDelete',

  middlewareConfigurationUpdate = 'middlewareConfigurationUpdate',

  voucherEmissionList = 'voucherEmissionList',
  voucherEmissionCreate = 'voucherEmissionCreate',
  voucherEmissionGet = 'voucherEmissionGet',
  voucherEmissionUpdate = 'voucherEmissionUpdate',
  voucherEmissionGenerateVouchers = 'voucherEmissionGenerateVouchers',
  voucherEmissionVoucherListHelpdesk = 'voucherEmissionVoucherListHelpdesk',
  voucherEmissionVoucherListAdmin = 'voucherEmissionVoucherListAdmin',

  voucherListHelpdesk = 'voucherListHelpdesk',
  voucherListAdmin = 'voucherListAdmin',
  voucherCreate = 'voucherCreate',
  voucherGet = 'voucherGet',
  voucherUpdate = 'voucherUpdate',
  voucherSearch = 'voucherSearch',

  promotionTemplateCreate = 'promotionTemplateCreate',
}

export const AclMap: {[key in AclKey]?: ActionListType} = {
  [AclKey.cashierList]: 'api.v1.admin.cashiers.create', // TODO: set right api
  [AclKey.cashierCreate]: 'api.v1.admin.cashiers.create',
  [AclKey.cashierGet]: 'api.v1.admin.cashiers.create', // TODO: set right api
  [AclKey.cashierUpdate]: 'api.v1.admin.cashiers.update',
  [AclKey.cashierPinChange]: 'api.v1.admin.cashiers.force.pin.change',
  [AclKey.cashierActivate]: 'api.v1.admin.cashiers.unblock',
  [AclKey.cashierDeactivate]: 'api.v1.admin.cashiers.block',

  [AclKey.pointOfSaleList]: 'api.v1.admin.pos.create', // TODO: set right api
  [AclKey.pointOfSaleCreate]: 'api.v1.admin.pos.create', // TODO: set right api
  [AclKey.pointOfSaleGet]: 'api.v1.admin.pos.create', // TODO: set right api
  [AclKey.pointOfSaleUpdate]: 'api.v1.admin.pos.update',
  [AclKey.pointOfSaleActivate]: 'api.v1.admin.pos.unblock',
  [AclKey.pointOfSaleDeactivate]: 'api.v1.admin.pos.block',

  [AclKey.pointOfSaleConfigurationCreate]: 'api.v1.admin.configurations.create.pos',
  [AclKey.pointOfSaleConfigurationGet]: 'api.v1.admin.configurations.get.pos',
  [AclKey.pointOfSaleConfigurationUpdate]: 'api.v1.admin.configurations.edit.pos',

  [AclKey.shopList]: 'api.v1.admin.shops.create', // TODO: set right api
  [AclKey.shopCreate]: 'api.v1.admin.shops.create',
  [AclKey.shopGet]: 'api.v1.admin.shops.create', // TODO: set right api
  [AclKey.shopUpdate]: 'api.v1.admin.shops.update',
  [AclKey.shopActivate]: 'api.v1.admin.shops.unblock',
  [AclKey.shopDeactivate]: 'api.v1.admin.shops.block',

  [AclKey.shopConfigurationCreate]: 'api.v1.admin.configurations.create.shop',
  [AclKey.shopConfigurationGet]: 'api.v1.admin.configurations.get.shop',
  [AclKey.shopConfigurationUpdate]: 'api.v1.admin.configurations.update.shop',

  [AclKey.configurationList]: 'api.v1.admin.configurations.get.list',
  [AclKey.configurationCreate]: 'api.v1.admin.configurations.create',
  [AclKey.configurationGet]: 'api.v1.admin.configurations.revision.get',
  [AclKey.configurationUpdate]: 'api.v1.admin.configurations.create.revision',

  [AclKey.pointOfSaleAttributeList]: 'api.v1.admin.pos.attributes.get.list',
  [AclKey.pointOfSaleAttributeCreate]: 'api.v1.admin.pos.attributes.create',
  [AclKey.pointOfSaleAttributeGet]: 'api.v1.admin.pos.attributes.get',
  [AclKey.pointOfSaleAttributeUpdate]: 'api.v1.admin.pos.attributes.update',
  [AclKey.pointOfSaleBulkAttributeAssign]: 'api.v1.admin.configurations.assign.pos.attribute',
  [AclKey.pointOfSaleBulkVersionChange]: 'api.v1.admin.pos.all.add.version',

  [AclKey.importList]: 'api.v1.admin.imports.upload', // TODO: set right api
  [AclKey.importUpload]: 'api.v1.admin.imports.upload',
  [AclKey.importGet]: 'api.v1.admin.imports.detail',
  [AclKey.importDownload]: 'api.v1.admin.imports.download',

  [AclKey.promotionList]: 'api.v1.admin.search.by.promotion.list', // TODO: set right api
  [AclKey.promotionCreate]: 'api.v1.admin.promotions.create',
  [AclKey.promotionGet]: 'api.v1.admin.promotions.get',
  [AclKey.promotionUpdate]: 'api.v1.admin.promotions.update',
  [AclKey.promotionBulkDelete]: 'api.v1.admin.promotions.create', // TODO: set right api
  [AclKey.promotionChangeBulkState]: 'api.v1.admin.promotions.create', // TODO: set right api
  [AclKey.promotionClearAndImport]: 'api.v1.admin.promotions.import',
  [AclKey.promotionChangeBulkExpiration]: 'api.v1.admin.promotions.bulk.expiration.set',
  [AclKey.promotionChangeBulkExpirationRelative]: 'api.v1.admin.promotions.bulk.expiration.set.relative',
  [AclKey.promotionChangeBulkExpirationAll]: 'api.v1.admin.promotions.all.bulk.expiration.set',
  [AclKey.promotionChangeBulkExpirationRelativeAll]: 'api.v1.admin.promotions.all.bulk.expiration.set.relative',

  [AclKey.promotionListList]: 'api.v1.admin.search.by.promotion.list', // TODO: set right api
  [AclKey.promotionListCreate]: 'api.v1.admin.promotion.lists.create',
  [AclKey.promotionListGet]: 'api.v1.admin.promotion.lists.get',
  [AclKey.promotionListUpdate]: 'api.v1.admin.promotion.lists.update',
  [AclKey.promotionListBulkDelete]: 'api.v1.admin.search.by.promotion.list', // TODO: set right api

  [AclKey.middlewareConfigurationUpdate]: 'api.v1.admin.configurations.create', // TODO: set right api
  // [AclKey.middlewareConfigurationUpdate]: 'api.v1.admin.middleware.configuration.update',

  [AclKey.voucherEmissionList]: 'api.v1.admin.voucher.emission.list.get',
  [AclKey.voucherEmissionCreate]: 'api.v1.admin.voucher.emission.create',
  [AclKey.voucherEmissionGet]: 'api.v1.admin.voucher.emission.get',
  [AclKey.voucherEmissionUpdate]: 'api.v1.admin.voucher.emission.update',
  [AclKey.voucherEmissionGenerateVouchers]: 'api.v1.admin.voucher.emission.update',
  [AclKey.voucherEmissionVoucherListHelpdesk]: 'api.v1.admin.voucher.list.for.emission.get',
  [AclKey.voucherEmissionVoucherListAdmin]: 'api.v1.admin.voucher.admin.list.for.emission.get',

  [AclKey.voucherListHelpdesk]: 'api.v1.admin.voucher.list.get',
  [AclKey.voucherListAdmin]: 'api.v1.admin.voucher.list.get.admin',
  [AclKey.voucherCreate]: 'api.v1.admin.voucher.create',
  [AclKey.voucherGet]: 'api.v1.admin.voucher.get',
  [AclKey.voucherSearch]: 'api.v1.admin.voucher.get.helpdesk',

  [AclKey.promotionTemplateCreate]: 'api.v1.admin.promotion.template.create',
};
