import EnumField from '@api/posMiddlewareCore/entity/EnumField';
import ArrayType from '@api/posMiddlewareCore/types/ArrayType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';

export default {
  updateTypes: {
    type: new ArrayType(new EntityType(EnumField)),
  },
  updateCondition: {
    type: new ArrayType(new EntityType(EnumField)),
  },
};
