import {emitTestEvent} from '@ui/helpers/testEvent';
import {initYup} from '@ui/helpers/validations';
import {TestEvent} from '@ui/tests/e2e/helpers/testEvents';
import {App} from 'vue';
import routes from './routes';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type CoreRequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  app: App<any>,
  preloadHooks: any[],
}

/**
 * Define what this module provides thru context
 */
export type CoreContext = CoreRequiredContext & {
}

export async function registerCoreModule(ctx: CoreRequiredContext) {
  initYup();

  await ctx.registerRoutes(routes);

  await ctx.registerTranslations(translations);

  await ctx.registerHookAfterEach((to) => {
    for (const matchedRoute of to.matched) {
      if (matchedRoute.path === '/') {
        continue;
      }

      emitTestEvent(`${TestEvent.routerNavigation}:${matchedRoute.name as string ?? matchedRoute.path}`);
    }
    emitTestEvent(`${TestEvent.routerNavigation}:${to.fullPath as string}`);
  });
}


