import {BrowserTracing} from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import {mapValues} from 'lodash-es';
import {App} from 'vue';
import {Router} from 'vue-router';
import {guid} from './guid';
import {getCurrentBuildHash, scenarioIdSessionStorage} from './persist';

let pendingRequests = 0;

export enum SentryTags {
  buildHash = 'buildHash',
  language = 'language',
  tag = 'tag',
}

export enum SentryTransactionTypes {
  StandaloneAxiosRequest = 'StandaloneAxiosRequest',
}


const sanitizeGuid = (guid: string) => guid.replace(/-/g, '');

export function sentrySetBuildHash() {
  Sentry.setTag(SentryTags.buildHash, getCurrentBuildHash());
}

export function sentrySetTag() {
  if (process.env.APP_VERSION) {
    Sentry.setTag(SentryTags.tag, process.env.APP_VERSION);
  }
}

export function sentryCurrentUnclosedContext() {
  const scope = Sentry.getCurrentHub().getScope();
  const transaction = scope.getTransaction();

  if (transaction && !transaction?.endTimestamp) {
    return {
      scope,
      transaction,
    };
  }

  return null;
}

/**
 * Transaction logic is:
 * - I can have only 1 parent transaction
 * - If I have active sellDocumentTransaction, workflows are recorded as events
 */

export function sentryEnsureStandaloneAjaxRequestTransaction() {
  const unclosedContext = sentryCurrentUnclosedContext();
  pendingRequests++;

  if (!unclosedContext) {
    Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(Sentry.startTransaction({
      name: SentryTransactionTypes.StandaloneAxiosRequest,
      traceId: sanitizeGuid(guid()),
      op: scenarioIdSessionStorage.get(),
    })));
  }
}

export function sentryFinishCurrentStandaloneAjaxRequestTransaction() {
  pendingRequests--;
  const unclosedContext = sentryCurrentUnclosedContext();

  if (
    unclosedContext &&
    unclosedContext.transaction.name === SentryTransactionTypes.StandaloneAxiosRequest &&
    !pendingRequests
  ) {
    unclosedContext.transaction.finish();
    unclosedContext.scope.clearBreadcrumbs();
  }
}

export function sentryFinishCurrentTransaction() {
  const unclosedContext = sentryCurrentUnclosedContext();

  if (!unclosedContext) {
    return;
  }

  unclosedContext.transaction.finish();
  unclosedContext.scope.clearBreadcrumbs();
}

export function setTransactionEvent(
  event: string,
  {
    type = 'event',
    start = new Date(),
    end = new Date(),
    status = 'ok',
  } = {},
) {
  const scope = Sentry.getCurrentHub().getScope();
  const transaction = scope.getTransaction();

  if (!transaction) {
    return;
  }

  const span = transaction.startChild({
    op: type,
    startTimestamp: (+start / 1000),
    endTimestamp: (+end / 1000),
    description: event,
  });

  span.setStatus(status);
  span.finish();
}

export function sentryRecordException(exception: Error, {message = null} = {}) {
  if (message) {
    exception.message = `${message} - ${exception.message}`;
  }

  Sentry.captureException(exception);
}

type SentryConfiguration = {
  dsn?: string,
  environment?: string,
  maxBreadcrumbs?: number,
  tracesSampleRate?: number,
  attachStackTrace?: boolean,
  sections?: {
    [key in SentryTransactionTypes]: {
      tracesSampleRate?: number,
    }
  },
}

/**
 * Note: we use || for some options because backend can return it as empty string
 */

const sanitizeSentryConfiguration = (config: SentryConfiguration): SentryConfiguration => {
  // eslint-disable-next-line max-len
  config.dsn = config.dsn === '${SENTRY_DSN}' ? null : (config.dsn ?? null);
  config.environment = config.environment || 'NotAssigned';
  config.maxBreadcrumbs = config.maxBreadcrumbs ?? 100;
  config.tracesSampleRate = config.tracesSampleRate ?? 1;
  config.attachStackTrace = config.attachStackTrace ?? false;
  config.sections = {...mapValues(SentryTransactionTypes, () => ({})), ...(config.sections ?? {})};
  config.sections = mapValues(config.sections, (section) => {
    section.tracesSampleRate = section.tracesSampleRate ?? config.tracesSampleRate ?? 1;
    return section;
  });

  return config;
};

let currentConfig = null;

const configurationsAreEqual = (newConfiguration) => {
  return JSON.stringify(newConfiguration) === JSON.stringify(currentConfig);
};

export function initSentry(ctx: {
  app: App<any>,
  router: Router,
  config?: SentryConfiguration,
}) {
  if (process.env.NODE_ENV === 'development') return;

  ctx.config = sanitizeSentryConfiguration(ctx.config ?? {});

  const currentDsn = Sentry.getCurrentHub().getClient()
    ?.getOptions().dsn ?? null;

  if (currentDsn && configurationsAreEqual(ctx.config)) {
    // eslint-disable-next-line no-console
    console.log('[Sentry] configs are equal, initialize skipped');
    return;
  }

  if (!currentDsn && !ctx.config.dsn) {
    console.warn('Sentry DSN is not set, Sentry is disabled');
    return;
  }

  Sentry.init({
    // debug: true,
    app: ctx.app,
    release: process.env.SENTRY_RELEASE,
    dsn: ctx.config.dsn,
    environment: ctx.config.environment,
    maxBreadcrumbs: ctx.config.maxBreadcrumbs,
    tracesSampleRate: ctx.config.tracesSampleRate,
    attachStacktrace: ctx.config.attachStackTrace,
    attachProps: true,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\/api/],
        traceFetch: true,
        traceXHR: true,

        idleTimeout: null,
        finalTimeout: null,
        startTransactionOnLocationChange: false,
        startTransactionOnPageLoad: false,
        markBackgroundTransactions: false,
      }),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb;
    },
    beforeSend(event) {
      return event;
    },
    tracesSampler: (samplingContext) => {
      if (process.env.NODE_ENV === 'development') {
        return true;
      }

      return ctx.config
        .sections
        ?.[samplingContext?.transactionContext?.name]
        ?.tracesSampleRate ?? ctx.config.tracesSampleRate;
    },
  });

  currentConfig = ctx.config;

  // eslint-disable-next-line no-console
  console.log('[Sentry] initialized');

  sentrySetBuildHash();
  sentrySetTag();
  return {};
}
