import {createAuthCallbackRedirect, RedirectPathName} from '@ui/helpers/redirect';
import {
  sentryEnsureStandaloneAjaxRequestTransaction,
  sentryFinishCurrentStandaloneAjaxRequestTransaction,
} from '@ui/helpers/sentry';
import {useAuthStore} from '@ui/modules/Auth/store/AuthStore';
import {AxiosInstance, AxiosRequestConfig} from 'axios';

type AppSpecificMapItem = (...args: any[]) => Promise<any>;

export class AppSpecificApi <APIS extends string[]> {
  constructor(public map: {[key in APIS[number]]?: AppSpecificMapItem} = {}) {}

  get call() {
    return this.map;
  }

  setApi(id: APIS[number], api: AppSpecificMapItem) {
    this.map[id] = api;
  }
}


export function axiosTokenInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use((config) => {
    const authStore = useAuthStore();
    config.headers['Authorization'] = `bearer ${authStore.authorizationHeader.value}`;
    return config;
  });

  return axios;
}

export function axiosUnauthorizedInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        return new Promise(() => {
          window.location.href = `${RedirectPathName.redirectLogin}?redirect_uri=${createAuthCallbackRedirect()}`;
        });
      } else {
        return Promise.reject(error);
      }
    },
  );

  return axios;
}

export function axiosConfigInterceptor(
  axios: AxiosInstance,
  patch: (config: AxiosRequestConfig) => AxiosRequestConfig,
) {
  axios.interceptors.request.use(patch);

  return axios;
}

export function axiosSentryRequestInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use((config) => {
    sentryEnsureStandaloneAjaxRequestTransaction();

    return config;
  }, (error) => {
    sentryFinishCurrentStandaloneAjaxRequestTransaction();
    return Promise.reject(error);
  });

  axios.interceptors.response.use(
    (response) => {
      sentryFinishCurrentStandaloneAjaxRequestTransaction();
      return response;
    },
    (error) => {
      sentryFinishCurrentStandaloneAjaxRequestTransaction();
      return Promise.reject(error);
    },
  );

  return axios;
}
