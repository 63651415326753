export default {
  cs: {
    pointOfSaleTransaction: {
      modalTransactionDetail: {
        title: 'Detail transakce',
        tabs: {
          financialCloseDay: {
            title: 'Finanční uzávěrka',
          },
          journal: {
            title: 'Pokladní deník',
            fetchError: 'Nepodařilo se načíst data',
            loading: 'Načítaní dat',
          },
        },
      },
      fields: {
        documentDate: 'Datum a čas',
        documentType: 'Typ',
        cashier: 'Uživatel',
        isSapProcessed: 'SAP',
      },
    },
  },
};
