import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-4 text-2xl pb-3 text-danger font-bold" }
const _hoisted_2 = { class: "pb-4 text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.open,
    zIndex: _ctx.ZLayer.critical
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('layout.components.crashState.title')), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('layout.components.crashState.body')), 1),
        _createElementVNode("div", null, [
          _createVNode(_component_AsyncButton, {
            test: 'crashStateButton',
            type: "button",
            class: "btn-basic bg-basic-light",
            onClick: _ctx.refresh
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('layout.components.crashState.refresh')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["open", "zIndex"]))
}