export default {
  cs: {
    cashier: {
      detail: {
        title: '#{personalNumber}',
        pinCode: {
          forceChangeMessage: 'Uživatel si musí při příštím přihlášení změnit PIN',
        },
      },
      grid: {
        title: 'Pokladní',
        actions: {
          create: {
            title: 'Založit',
          },
          pinChange: {
            title: 'Vynutit změnu pinu',
            confirm: 'Opravdu si přejete vynutit změnu pinu?',
          },
          activate: {
            confirm: 'Opravdu chcete pokladní aktivovat?',
          },
          deactivate: {
            confirm: 'Opravdu chcete pokladní deaktivovat?',
          },
        },
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          roles: 'Role',
          rolesEnum: {
            allOptions: 'Všechny role',
          },
          isValid: 'Platnost',
          isValidEnum: {
            allOptions: 'Všechny platnosti',
          },
          isActive: 'Aktivní',
          isActiveEnum: {
            allOptions: 'Všechny stavy',
          },
        },
        advancedFilters: {
          title: 'Filtrovat pokladní',
          search: 'Vyhledat',
          placeholders: {
            search: 'Zadejte název (min. 2 znaky)',
            roles: 'Vyberte role',
            isValid: 'Vyberte platnost',
            isActive: 'Vyberte stav',
          },
        },
      },
      form: {
        create: {
          title: 'Nový/á pokladní',
        },
        update: {
          title: 'Úprava pokladní/ho ',
        },
        placeholders: {
          personalNumber: 'Zadejte osobní číslo',
          firstName: 'Zadejte jméno',
          lastName: 'Zadejte příjmení',
          email: 'Zadejte email',
          phone: 'Zadejte telefon',
          roles: 'Vyberte role',
          pinCode: 'Zadejte pin',
          primaryShop: 'Zadejte primární shop',
        },
      },
      fields: {
        id: 'ID',
        personalNumber: 'Osobní číslo',
        firstName: 'Jméno',
        lastName: 'Příjmení',
        name: 'Jméno',
        pinCode: 'PIN',
        email: 'E-mail',
        phone: 'Telefon',
        primaryShop: 'Primární prodejna',
        validFrom: 'Platnost od',
        validTill: 'Platnost do',
        isValid: 'Platný',
        isValidEnum: {
          true: 'Platný',
          false: 'Neplatný',
        },
        isActive: 'Aktivní',
        isActiveEnum: {
          true: 'Aktivní',
          false: 'Neaktivní',
        },
        state: 'Stav',
        roles: 'Role',
        rolesEnum: {
          admin: 'Admin',
          cashier_manager: 'Vedoucí',
          cashier: 'Pokladní',
          tester: 'Tester',
          tester2: 'Tester2',
          title: 'Role',
        },
      },
    },
  },
};
