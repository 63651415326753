import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/points-of-sale',
    name: 'points-of-sale',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.pointOfSaleList,
    },
  },
  {
    path: '/point-of-sale/:id',
    name: 'point-of-sale',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
    redirect: {name: 'point-of-sale-overview'},
    children: [
      {
        path: 'overview',
        name: 'point-of-sale-overview',
        component: () => import('./Detail/tabs/TabOverview.vue'),
      },
      {
        path: 'synchronizations',
        name: 'point-of-sale-synchronizations',
        component: () => import('./Detail/tabs/TabSynchronizations.vue'),
      },
      {
        path: 'versions',
        name: 'point-of-sale-versions',
        component: () => import('./Detail/tabs/TabVersions.vue'),
      },
      {
        path: 'configurations',
        name: 'point-of-sale-configurations',
        component: () => import('./Detail/tabs/TabConfigurations.vue'),
      },
      {
        path: 'transactions',
        name: 'point-of-sale-transactions',
        component: () => import('./Detail/tabs/TabTransactions.vue'),
      },
    ],
  },
  {
    path: '/point-of-sale/update/:id',
    name: 'point-of-sale-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
  {
    path: '/point-of-sale/create',
    name: 'point-of-sale-create',
    component: () => import('./Form/CreatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.pointOfSaleCreate,
    },
  },
] as RegisterRoutesOptions;
