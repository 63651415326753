
import {useTransitions} from '@ui/helpers/transitions';
import {InteractionLevel} from '@ui/modules/Core/types';
import {defineComponent, PropType} from 'vue';
import Button from '../button/Button.vue';
import ModalDetachedBody from './ModalDetachedBody.vue';
import ModalDetachedButtons from './ModalDetachedButtons.vue';
import ModalDetachedClose from './ModalDetachedClose.vue';

export default defineComponent({
  name: 'ModalConfirmContent',
  components: {Button, ModalDetachedButtons, ModalDetachedClose, ModalDetachedBody},
  props: {
    hasRejectBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String as PropType<InteractionLevel>,
      required: false,
      default: InteractionLevel.default,
    },
  },
  emits: ['close', 'confirm', 'reject'],
  setup() {
    const {transitionLeaving} = useTransitions();

    return {
      transitionLeaving,
    };
  },
});
