import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {store as voucherGridStore} from '@middleware-ui/modules/Voucher/Grid/grid';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/vouchers',
    name: 'vouchers',
    component: () => import('./Grid/GridPage.vue'),
    beforeEnter: (to, from, next) => {
      if (from.name !== 'voucher-events') {
        voucherGridStore.setAdminView(false);
      }

      next();
    },
  },
  {
    path: '/voucher-search',
    name: 'voucher-search',
    component: () => import('./Search/SearchPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.voucherSearch,
    },
  },
  {
    path: '/voucher/:id',
    name: 'voucher',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
    redirect: {name: 'voucher-events'},
    children: [
      {
        path: 'vouchers',
        name: 'voucher-events',
        component: () => import('./Detail/tabs/TabVoucherEvents.vue'),
      },
    ],
  },
  {
    path: '/voucher-emission/:id/voucher/bulk-create',
    name: 'voucher-emission-bulk-create',
    component: () => import('./Form/CreatePage.vue'),
    props: true,
  },
] as RegisterRoutesOptions;
