
export default {
  cs: {
    app: {
      appName: 'Middleware',
    },
    layout: {
      components: {
        aside: {
          menu: {
            dashboard: 'Dashboard',
            shop: 'Prodejny',
            pointOfSale: 'Pokladny',
            pointOfSaleAttribute: 'POS attributy',
            cashier: 'Pokladní',
            configuration: 'Konfigurace',
            pointOfSaleConfiguration: 'Konfigurace POS',
            middlewareConfiguration: 'Middleware',
            promotionList: 'Promoční koše',
            promotion: 'Promoce',
            import: 'Dávkové importy',
            voucher: 'Poukázky',
            voucherEmission: 'Emise poukázek',
            voucherOverview: 'Přehled poukázek',
            voucherSearch: 'Poukázky helpdesk',
          },
        },
      },
    },
    general: {
      document: {
        types: {
          sellDocument: 'Prodej',
          closeDay: 'Uzávěrka',
          financialCloseDay: 'Finanční uzávěrka',
          printDocument: 'Dokument',
          nonFiscalDocument: 'Nefiskální dokument',
          finDocument: 'Finanční dokument',
          safebag: 'Safebag',
          income: 'Příjmový doklad',
          expenditure: 'Výdejový doklad',
          stockReport: 'Stav skladu',
          inventoryDocument: 'Inventura',
          pOSStateDocument: 'Stav pokladny',
          pOSPayment: 'Platby',
          lotteryCloseDay: 'Uzávěrka loterie',
          openCashDrawer: 'Manuální otevření zásuvky',
          posPaymentZReport: 'Uzávěrka plateb. terminálu',
          posPaymentCancel: 'Zrušení platby',
          posPaymentRefund: 'Vrácení platby',
          dialogResult: 'Potvrzení obsluhy',
          inventorySummaryDocument: 'Inventura (souhrn)',
          inventoryFindDiffsDocument: 'Inventura (hledání rozdílů)',
          initPrinter: 'Inicializace tiskárny',
          contentDelayedSellStorno: 'Návrat - paragon',
          contentDelayedSell: 'Prodej - paragon',
          logisticDocument: 'Logistický dokument',
          goodsReceiptFromDC: 'Příjem zboží z DC',
          goodsReturnToDC: 'Návrat zboží do DC',
          goodsOrderFromDC: 'Objednávka zboží z DC',
          goodsReceiptFromPress: 'Příjem tiskovin',
          goodsReceiptFromExternalSupplier: 'Příjem zboží od externího dodavatele',
          goodsReturnToExternalSupplier: 'Návrat zboží externímu dodavateli',
          goodsOrderFromExternalSupplier: 'Objednávka zboží od externího dodavatele',
          goodsReturnToPress: 'Remitenda tiskovin',
          goodsDepreciationConsumption: 'Odpis do spotřeby',
          goodsDepreciationDisposal: 'Odpis do likvidace',
          stockBalancing: 'Rovnání skladu',
          goodsReceiptFromPlant: 'Přeskladnění příjem',
          goodsTransferToPlant: 'Přeskladnění výdej',
        },
      },
    },
  },
};
