
import {ZLayer} from '@ui/constants/zLayer';
import {emitTestEvent} from '@ui/helpers/testEvent';
import {useCoreStore} from '@ui/modules/Core/store/CoreStore';
import {IModal} from '@ui/modules/Core/types';
import {TestEvent} from '@ui/tests/e2e/helpers/testEvents';
import {
  defineComponent,
  onBeforeUnmount,
  ref,
  Ref,
  watch,
  computed,
} from 'vue';
import PhIcon from '../PhIcon.vue';
import TransitionWrapper from '../TransitionWrapper.vue';

export default defineComponent({
  components: {
    PhIcon,
    TransitionWrapper,
  },
  inheritAttrs: false,
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    zIndex: {
      type: Number,
      default: ZLayer.modal,
    },

    silenceCloseListeners: {
      type: Boolean,
      required: false,
      default: false,
    },

    offsetModalContentX: {
      type: String,
      required: false,
      default: null,
    },
    offsetModalContentY: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['close'],
  setup(props, {emit}) {
    const coreStore = useCoreStore();
    const enterTime = ref(300);
    const leaveTime = ref(200);
    const isOpened = ref(props.open);

    const modal: Ref<IModal> = ref(null);

    const onClose = (state) => {
      if (modal.value) {
        emit('close', state);
      }
    };

    const onOpen = () => {
      modal.value = coreStore.requestModal(onClose, {
        zIndexBase: props.zIndex,
        silenceCloseListeners: props.silenceCloseListeners,
      });
      // @ts-ignore
      document.activeElement.blur();
    };

    watch(() => props.open, (newVal) => {
      if (props.open && isOpened.value) {
        emitTestEvent(TestEvent.modalClosed);
      } else if (!props.open && !isOpened.value) {
        emitTestEvent(TestEvent.modalOpened);
      }

      if (newVal && !modal.value) {
        onOpen();
      } else if (!newVal && modal.value) {
        coreStore.removeModal(modal.value.id);
        modal.value = null;
      }
    }, {immediate: true});

    function modalOpened() {
      isOpened.value = props.open;
      emitTestEvent(TestEvent.modalOpened);
    }

    function modalClosed() {
      isOpened.value = props.open;
      emitTestEvent(TestEvent.modalClosed);
    }

    onBeforeUnmount(() => {
      try {
        if (modal.value) {
          coreStore.removeModal(modal.value.id);
        }
      } catch (err) {
        console.error(err);
      }
    });

    const totalZIndex = computed(() => {
      return modal.value?.zIndexBase + modal.value?.zIndexOffset ?? 0;
    });

    return {
      enterTime,
      leaveTime,
      modalOpened,
      modalClosed,
      onClose,
      totalZIndex,
    };
  },
});
