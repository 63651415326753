import ErrorResponse from '@api/posMiddlewareCore/entity/ErrorResponse';
import VoucherPreviewDtoIEnvelopeIPagingObject from '@api/posMiddlewareCore/entity/VoucherPreviewDtoIEnvelopeIPagingObject';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import URLQueryParamsType from '@api/posMiddlewareCore/types/URLQueryParamsType';
import VoidType from '@api/posMiddlewareCore/types/VoidType';
import axios from '../axios';

export default {
  name: 'api.v1.admin.voucher.list.get',
  method: <'GET'>'GET',
  path: '/api/v1/admin/vouchers',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(VoucherPreviewDtoIEnvelopeIPagingObject),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new URLQueryParamsType<{ fields?: string, limit?: string, offset?: string, text?: string, operator?: string, sort?: string, reverseNullSort?: string, }>(),
  axios,
};
