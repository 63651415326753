import {PhIcons} from '@ui/helpers/phIcons';
import {useAuthStore} from '@ui/modules/Auth/store/AuthStore';
import {
  every,
  flow,
  map,
  reject,
  some,
} from 'lodash-es';
import {Component} from 'vue';
import {
  LocationAsRelativeRaw,
  RouteLocationOptions,
  RouteQueryAndHash,
  useRouter,
} from 'vue-router';

type MenuItemAbstract = {
  label: string,
  isActive?: boolean,
  asGroup?: MenuItemTypeGroup,
  asItem?: MenuItemTypeItem,
  asDropdown?: MenuItemTypeDropdown,
  isDropdown?: boolean,
  isGroup?: boolean,
  isItem?: boolean,
}

export type MenuItemTypeItem = {
  icon?: PhIcons,
  sectionRoutes?: string[],
  to: RouteQueryAndHash & LocationAsRelativeRaw & RouteLocationOptions,
} & MenuItemAbstract

export type MenuItemTypeGroup = {
  group: string,
  icon?: PhIcons,
  items: Array<MenuItemTypeGroup | MenuItemTypeItem | MenuItemTypeDropdown>,
} & MenuItemAbstract

export type MenuItemTypeDropdown = {
  icon?: PhIcons,
  dropdown: Component,
} & MenuItemAbstract

export type Menu = Array<MenuItemTypeGroup | MenuItemTypeItem | MenuItemTypeDropdown>;

export function useMenu () {
  const router = useRouter();
  const authStore = useAuthStore();

  const isMenuItemTypeItemActive = (item: MenuItemTypeItem): boolean => {
    try {
      if (router.resolve(item.to).name === router.currentRoute.value.name) {
        return true;
      }

      for (const routeName of item.sectionRoutes) {
        if (routeName === router.currentRoute.value.name) {
          return true;
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const isMenuItemInaccessible = (item: MenuItemTypeItem): boolean => {
    try {
      const {
        aclKeyForAccess,
      } = router.resolve(item.to).meta;

      if (!aclKeyForAccess) {
        return false;
      }

      return !authStore.hasAccess(aclKeyForAccess);
    } catch (e) {
      return false;
    }
  };

  const haveActiveMenuSubItem = (group: MenuItemTypeGroup): boolean => {
    return some(group.items, (item) => {
      if (isMenuItemTypeDropdown(item)) {
        return false;
      } else if (isMenuItemTypeGroup(item)) {
        return haveActiveMenuSubItem(item);
      } else {
        return isMenuItemTypeItemActive(item);
      }
    });
  };

  const isMenuItemTypeGroup = (item): item is MenuItemTypeGroup => {
    return Object.prototype.hasOwnProperty.call(item, 'items');
  };

  const isMenuItemTypeDropdown = (item): item is MenuItemTypeDropdown => {
    return Object.prototype.hasOwnProperty.call(item, 'dropdown');
  };

  const sniffMenuState = (menu: Menu) => {
    return flow([
      (menu) => map(menu, (item) => {
        item.asDropdown = null;
        item.asGroup = null;
        item.asItem = null;

        item.isDropdown = false;
        item.isGroup = false;
        item.isItem = false;

        if (isMenuItemTypeDropdown(item)) {
          item.isActive = false;
          item.asDropdown = item;
          item.isDropdown = true;
          return item;
        } else if (isMenuItemTypeGroup(item)) {
          item.isActive = haveActiveMenuSubItem(item);
          item.items = sniffMenuState(item.items);
          item.asGroup = item;
          item.isGroup = true;
          return item;
        } else {
          item.isActive = isMenuItemTypeItemActive(item);
          item.asItem = item;
          item.isItem = true;
          return item;
        }
      }),
      (menu) => reject(menu, (item) => {
        if (isMenuItemTypeDropdown(item)) {
          return false;
        } else if (isMenuItemTypeGroup(item)) {
          return every(item.items, isMenuItemInaccessible);
        } else {
          return isMenuItemInaccessible(item);
        }
      }),
    ])(menu) as Menu;
  };

  return {
    isMenuItemTypeGroup,
    isMenuItemTypeDropdown,
    sniffMenuState,
  };
}
