import ILinkObject from '@api/posMiddlewareCore/entity/ILinkObject';
import UserDto from '@api/posMiddlewareCore/entity/UserDto';
import ArrayType from '@api/posMiddlewareCore/types/ArrayType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import StringType from '@api/posMiddlewareCore/types/StringType';

export default {
  operationId: {
    type: new StringType(),
  },
  links: {
    type: new ArrayType(new EntityType(ILinkObject)),
  },
  data: {
    type: new EntityType(UserDto),
  },
};
