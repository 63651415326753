import VoucherPreviewDtoIEnvelope from '@api/posMiddlewareCore/entity/VoucherPreviewDtoIEnvelope';
import ArrayType from '@api/posMiddlewareCore/types/ArrayType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import NumberType from '@api/posMiddlewareCore/types/NumberType';

export default {
  data: {
    type: new ArrayType(new EntityType(VoucherPreviewDtoIEnvelope)),
  },
  limit: {
    type: new NumberType(),
  },
  offset: {
    type: new NumberType(),
  },
  total: {
    type: new NumberType(),
  },
};
