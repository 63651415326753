import {App} from 'vue';
import routes from './routes';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type PointOfSaleVersionsRequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  app: App<any>,
}

/**
 * Define what this module provides thru context
 */
export type CoreContext = PointOfSaleVersionsRequiredContext & {
}

export async function registerPointOfSaleVersionModule(ctx: PointOfSaleVersionsRequiredContext) {
  await ctx.registerRoutes(routes);

  await ctx.registerTranslations(translations);

  return {};
}
