import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/404',
    name: '404',
    component: () => import('./404/404Page.vue'),
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('./Unauthorized/UnauthorizedPage.vue'),
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('./Typography/TypographyPage.vue'),
  },
] as RegisterRoutesOptions;
