import {RedirectPathName} from '@ui/helpers/redirect';
import {RegisterRoutesOptions} from '@ui/plugins/modules';
import {useAuthStore} from './store/AuthStore';

export default [
  {
    path: RedirectPathName.redirectLogin,
    name: 'redirect-login',
    async beforeEnter(to, from, next) {
      const authStore = useAuthStore();
      await authStore.identityUserManager.value.signinRedirect(...[
        ...(to.query.redirect_uri ? [
          {
            extraQueryParams: {
              custom_callback_uri: encodeURIComponent(<string>to.query.redirect_uri),
            },
          },
        ] : []),
      ]);
      await new Promise(() => {});
    },
  },
  {
    path: RedirectPathName.redirectLogout,
    name: 'redirect-logout',
    async beforeEnter(to, from, next) {
      const authStore = useAuthStore();
      await authStore.identityUserManager.value.signoutRedirect();
      await new Promise(() => {});
    },
  },
  {
    path: RedirectPathName.redirectLoginCallback,
    name: 'redirect-login-callback',
    async beforeEnter(to, from, next) {
      const authStore = useAuthStore();
      let route;

      try {
        const callbackUrl = new URL(
          decodeURIComponent(new URL(window.location.href).searchParams.get('custom_callback_uri')),
        );

        route = `${callbackUrl.pathname}${callbackUrl.search ?? ''}`;
      } catch (e) {
        route = {name: 'dashboard'};
      }

      try {
        await authStore.identityUserManager.value.signinRedirectCallback();
      } catch (e) {
        console.error(e);
      }

      try {
        await authStore.fetchIdentityUser();
        next(route);
      } catch (e) {
        console.error(e);
        next({name: 'redirect-login'});
      }
    },
  },
] as RegisterRoutesOptions;
