import {PointOfSaleUpdateStatusMessageCode} from '@api/posMiddlewareCore/constants/pointOfSaleUpdateStatusMessageCode';
import {PointOfSaleUpdateStatusType} from '@api/posMiddlewareCore/constants/pointOfSaleUpdateStatusType';

export default {
  cs: {
    updateStatus: {
      grid: {
        cells: {
          lastVersion: {
            history: 'Historie',
            description: '{createdAt} - {type}',
            typeConsolidated: {
              planned: 'Naplánováno',
              running: 'Probíhá aktualizace',
              cancelled: 'Zrušeno',
              success: 'Úspěšně nainstalováno',
            },
          },
        },
      },
      fields: {
        typeEnum: {
          [PointOfSaleUpdateStatusType.archivedByRegistration]: 'Archivovaný při registraci',
          [PointOfSaleUpdateStatusType.planned]: 'Update byl naplánován',
          [PointOfSaleUpdateStatusType.synced]: 'POSka update přijala',
          [PointOfSaleUpdateStatusType.waitingForCapacity]: 'Čeká se na stažení ve frontě',
          [PointOfSaleUpdateStatusType.downloading]: 'Stahuje se',
          [PointOfSaleUpdateStatusType.downloaded]: 'Byl stáhnut',
          [PointOfSaleUpdateStatusType.cancelPlanned]: 'Zrušení updatu bylo naplánováno',
          [PointOfSaleUpdateStatusType.cancelled]: 'Update byl zrušen na POSce',
          [PointOfSaleUpdateStatusType.updating]: 'Update byl spuštěn',
          [PointOfSaleUpdateStatusType.updated]: 'Update byl nainstalován úspěšně',
          [PointOfSaleUpdateStatusType.updateFailed]: 'Update se nepodařilo nainstalovat',
          [PointOfSaleUpdateStatusType.downloadFailed]: 'Stažení se nezdařilo',
          [PointOfSaleUpdateStatusType.updateNotStarted]: 'Nesplněny podmínky pro update',
          none: 'Žádný',
        },
        messageCodeEnum: {
          [PointOfSaleUpdateStatusMessageCode.streamReadTimeout]: 'Vypršel čas při čtení streamu z centrálního serveru.',
          [PointOfSaleUpdateStatusMessageCode.dateTimeConditionNotFulfilled]: 'Nebyla splněna časová podmínka.',
          [PointOfSaleUpdateStatusMessageCode.transactionSyncConditionNotFulfilled]: 'Některé transakce nejsou synchronizované na centrální server.',
          [PointOfSaleUpdateStatusMessageCode.financialCloseDayConditionNotFulfilled]: 'Nebyla provedena finanční uzávěrka.',
          [PointOfSaleUpdateStatusMessageCode.existingInstallerFileConditionNotFulfilled]: 'Soubor s novou aktualizací neexistuje.',
          [PointOfSaleUpdateStatusMessageCode.existingUpdateConditionNotFulfilled]: 'Není nastavený žádný update.',
          [PointOfSaleUpdateStatusMessageCode.correctDataFormatConditionNotFulfilled]: 'Nepodařilo se přečíst formát nastaveného datumu.',
        },
      },
    },
  },
};
