
import {computed, defineComponent} from 'vue';

export default defineComponent({
  name: 'Logo',
  inheritAttrs: false,
  setup() {
    const logoBase64 = computed(() => {
      return require('@ui/assets/images/logo.svg').default;
    });

    return {
      logoBase64,
    };
  },
});
