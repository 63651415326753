import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/middleware-configuration/update',
    name: 'middleware-configuration-update',
    component: () => import('./Form/UpdatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.middlewareConfigurationUpdate,
    },
  },
] as RegisterRoutesOptions;
