import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/cashiers',
    name: 'cashiers',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.cashierList,
    },
  },
  {
    path: '/cashier/:id',
    name: 'cashier',
    component: () => import('./Detail/DetailPage.vue'),
    props: true,
  },
  {
    path: '/cashier/update/:id',
    name: 'cashier-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
  {
    path: '/cashier/create',
    name: 'cashier-create',
    component: () => import('./Form/CreatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.cashierCreate,
    },
  },
] as RegisterRoutesOptions;
