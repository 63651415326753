import {ConfigurationState} from '@api/posMiddlewareCore/constants/configurationState';

export default {
  cs: {
    configuration: {
      detail: {
        title: 'Konfigurace - {name}',
        actions: {
          posAttributesAssign: {
            title: 'Přiřadit na POS',
          },
          compatibility: {
            title: 'Kompatibilita',
          },
          archive: {
            confirm: 'Opravdu chcete konfiguraci archivovat?',
          },
        },
        tabs: {
          assignedPos: {
            title: 'Přiřazené pokladny',
          },
          configurationPreview: {
            title: 'Náhled',
          },
        },
      },
      grid: {
        title: 'Konfigurace',
        filters: {
          name: 'Vyhledat',
          data: 'Vyhledat v konfiguraci',
          state: 'Stav',
          activeAttributeIds: 'POS atributy (pouze pro aktivní konfigurace)',
          createdByName: 'Autor',
        },
        actions: {
          create: {
            title: 'Nová konfigurace',
          },
          compatibility: {
            title: 'Kompatibilita',
          },
        },
        advancedFilters: {
          title: 'Filtrovat konfigurace',
          placeholders: {
            name: 'Zadejte název',
            data: 'Zadejte text',
            state: 'Vyberte stav',
            activeAttributeIds: 'Vyberte atributy',
            createdByName: 'Zadejte autora',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvoření nové konfigurace',
        },
        readonly: {
          title: 'Konfigurace {name}',
        },
        update: {
          title: 'Editace konfigurace',
          actions: {
            archivePreviousAndReassign: {
              title: 'Uložit a archivovat předchozí',
            },
          },
        },
        placeholders: {
          name: 'Zadejte název',
          priority: 'Zadejte prioritu',
          schemaId: 'Vyberte schéma',
        },
        tabs: {
          editor: {
            title: 'Editor',
          },
          code: {
            title: 'Kód',
          },
          detail: {
            title: 'Detail',
          },
        },
      },
      fields: {
        name: 'Název',
        priority: 'Priorita',
        versionNumber: 'Číslo verze',
        state: 'Stav',
        stateEnum: {
          [ConfigurationState.enabled]: 'Povolený',
          [ConfigurationState.active]: 'Aktivní',
          [ConfigurationState.draft]: 'Koncept',
          [ConfigurationState.archive]: 'Archiv',
        },
        schemaId: 'Verze schéma',
        assignments: 'Přiřazení',
        createdByName: 'Vytvořil',
        createdDate: 'Datum vytvoření',
        data: 'Data',
        publishedDate: 'Publikováno',
        created: 'Vytvořeno',
      },
      modalConfigurationCompatibility: {
        title: 'Kompatibilita konfigurace {name}',
        grid: {
          semVersion: 'Verze schéma',
          isDefault: 'Defaultní',
          schemaIsValid: 'Validní schéma',
          configurationIsValid: 'Validní konfigurace',
          evaluate: 'Vyhodnotit',
          actions: {
            checkCompatibility: {
              title: 'Ověřit kompatibilitu',
            },
          },
        },
      },
    },
  },
};
