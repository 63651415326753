import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isRendered)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({entering: _ctx.entering, leaving: _ctx.leaving, entered: _ctx.entered, isRendered: _ctx.isRendered})))
      ]))
    : _createCommentVNode("", true)
}