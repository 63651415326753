import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_ModalDetachedBody = _resolveComponent("ModalDetachedBody")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!

  return (!_ctx.transitionLeaving)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModalDetachedClose, {
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }),
        _createVNode(_component_ModalDetachedBody, { type: _ctx.type }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["type"]),
        _createVNode(_component_ModalDetachedButtons, {
          single: !_ctx.hasRejectBtn
        }, {
          default: _withCtx(() => [
            (_ctx.hasRejectBtn)
              ? (_openBlock(), _createBlock(_component_Button, _normalizeProps({
                  key: 0,
                  class: "btn-outline-red",
                  [(_ctx.$test.confirm) || ""]: 'reject',
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reject')))
                }), {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "rejectBtn", {}, () => [
                      _createTextVNode(_toDisplayString(_ctx.$t('action.reject')), 1)
                    ])
                  ]),
                  _: 3
                }, 16))
              : _createCommentVNode("", true),
            _createVNode(_component_Button, _normalizeProps({
              class: {
          'btn-outline-green': _ctx.hasRejectBtn,
          'btn-green': !_ctx.hasRejectBtn
        },
              [(_ctx.$test.confirm) || ""]: _ctx.hasRejectBtn ? 'confirm' : 'continue',
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm')))
            }), {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "confirmBtn", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.hasRejectBtn ? _ctx.$t('action.confirm') : _ctx.$t('action.continue')), 1)
                ])
              ]),
              _: 3
            }, 16, ["class"])
          ]),
          _: 3
        }, 8, ["single"])
      ]))
    : _createCommentVNode("", true)
}