import {App} from 'vue';
import routes from './routes';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type PointOfSaleSynchronizationsRequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  app: App<any>,
}

/**
 * Define what this module provides thru context
 */
export type CoreContext = PointOfSaleSynchronizationsRequiredContext & {
}

export async function registerPointOfSaleSynchronizationModule(ctx: PointOfSaleSynchronizationsRequiredContext) {
  await ctx.registerRoutes(routes);

  await ctx.registerTranslations(translations);

  return {};
}
