
import {HLAsyncButton} from '@designeo/vue-helpers';
import {defineComponent} from 'vue';
import ButtonBase from './ButtonBase.vue';

export default defineComponent({
  name: 'Button',
  components: {
    HLAsyncButton,
    ButtonBase,
  },
  inheritAttrs: false,
  props: {
    onClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
});
