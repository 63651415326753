export enum DocumentType {
  closeDay = 'CloseDay',
  dialogResult = 'DialogResult',
  financialCloseDay = 'FinancialCloseDay',
  finDocument = 'FinDocument',
  inventoryDocument = 'InventoryDocument',
  inventorySummaryDocument = 'InventorySummaryDocument',
  inventoryFindDiffsDocument = 'InventoryFindDiffsDocument',
  lotteryCloseDay = 'LotteryCloseDay',
  logisticDocument = 'LogisticDocument',
  nonFiscalDocument = 'NonFiscalDocument',
  openCashDrawer = 'OpenCashDrawer',
  posPayment = 'POSPayment',
  posPaymentCancel = 'POSPaymentCancel',
  posPaymentRefund = 'POSPaymentRefund',
  posPaymentZReport = 'POSPaymentZReport',
  posStateDocument = 'POSStateDocument',
  posSalesDocument = 'POSSalesDocument',
  printDocument = 'PrintDocument',
  safeBag = 'Safebag',
  sellDocument = 'SellDocument',
  stockReport = 'StockReport',
  initPrinter = 'InitPrinter',
  display = 'Display',
  goodsReceiptFromDC = 'GoodsReceiptFromDC',
  goodsReturnToDC = 'GoodsReturnToDC',
  goodsOrderFromDC = 'GoodsOrderFromDC',
  goodsReceiptFromPress = 'GoodsReceiptFromPress',
  goodsReceiptFromExternalSupplier = 'GoodsReceiptFromExternalSupplier',
  goodsReturnToExternalSupplier = 'GoodsReturnToExternalSupplier',
  goodsOrderFromExternalSupplier = 'GoodsOrderFromExternalSupplier',
  goodsReturnToPress = 'GoodsReturnToPress',
  goodsDepreciationConsumption = 'GoodsDepreciationConsumption',
  goodsDepreciationDisposal = 'GoodsDepreciationDisposal',
  goodsReceiptFromPlant = 'GoodsReceiptFromPlant',
  goodsTransferToPlant = 'GoodsTransferToPlant',
  stockBalancing = 'StockBalancing',
  customerDetail = 'CustomerDetail'
}
