
import {HLDropdown} from '@ui/components/headless/Dropdown';
import PhIcon from '@ui/components/PhIcon.vue';
import {injectAsideContext} from '@ui/helpers/aside';
import {HLPhIconFallback} from '@ui/helpers/phIcons';
import {useAuthStore} from '@ui/modules/Auth/store/AuthStore';
import {computed, defineComponent} from 'vue';

export default defineComponent({
  name: 'AsideApplications',
  components: {
    HLPhIconFallback,
    PhIcon,
    HLDropdown,
  },
  setup() {
    const {collapsed, collapsedAfterAnimation} = injectAsideContext();
    const authStore = useAuthStore();

    const systems = computed(() => authStore.otherSystems.value);

    const navigate = async (system) => {
      window.location.href = system.baseUri;
    };

    return {
      systems,
      navigate,
      collapsed,
      collapsedAfterAnimation,
    };
  },
});
