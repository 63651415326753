
import {useFilters} from '@ui/helpers/filters';
import {computed, defineComponent} from 'vue';
import {gridCellProps} from './gridCell';

export default defineComponent({
  name: 'GridCellDateTime',
  props: {
    ...gridCellProps,
  },
  setup(props) {
    const {dateTimeFormat} = useFilters();
    const label = computed(() => {
      if (!props.value) {
        return null;
      }

      return dateTimeFormat(props.value);
    });

    return {
      label,
    };
  },
});
