import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/point-of-sale/:id/configuration/update',
    name: 'point-of-sale-configuration-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
  {
    path: '/point-of-sale/:id/configuration/readonly',
    name: 'point-of-sale-configuration-readonly',
    component: () => import('./Form/ReadonlyPage.vue'),
    props: true,
  },
  {
    path: '/point-of-sale/:id/configuration/create',
    name: 'point-of-sale-configuration-create',
    component: () => import('./Form/CreatePage.vue'),
    props: true,
    meta: {
      aclKeyForAccess: AclKey.configurationCreate,
    },
  },
] as RegisterRoutesOptions;
