
import {
  defineComponent,
  ref,
  watch,
  onBeforeUnmount,
} from 'vue';
import IconLoaderPulse from '../LoaderPulse.vue';

export default defineComponent({
  name: 'ButtonBase',
  components: {IconLoaderPulse},
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    throttling: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const innerLoading = ref(null);
    const changeTimeout = ref(null);

    watch(() => props.loading, (value) => {
      if (props.throttling) {
        clearTimeout(changeTimeout.value);
        changeTimeout.value = setTimeout(() => {
          innerLoading.value = value;
        }, value ? 0 : 500);
      } else {
        innerLoading.value = value;
      }
    }, {immediate: true});

    onBeforeUnmount(() => {
      clearTimeout(changeTimeout.value);
    });

    return {
      innerLoading,
    };
  },
});
