export default {
  cs: {
    action: {
      back: 'Zpět',
      search: 'Vyhledat',
      save: 'Uložit',
      open: 'Otevřít',
      close: 'Zavřit',
      cancel: 'Zrušit',
      continue: 'Pokračovat',
      confirm: 'Potvrdit',
      reject: 'Odmítnout',
      edit: 'Upravit',
      ignore: 'Ignorovat',
      create: 'Vytvořit',
      activate: 'Aktivovat',
      deactivate: 'Deaktivovat',
      archive: 'Archivovat',
      generate: 'Vygenerovat',
      download: 'Stáhnout',
      export: 'Exportovat',
      add: 'Přidat',
      import: 'Importovat',
      filter: 'Filtrovat',
      detail: 'Detail',
      remove: 'Odstranit',
    },
    common: {
      no: 'Ne',
      yes: 'Ano',
      from: 'od',
      to: 'do',
      by: 'z',
      or: 'nebo',
      points: '{n} bodů | {n} bod | {n} body | {n} bodů',
      pointsShort: '{n} b. | {n} b. | {n} b. | {n} b.',
      recordSaved: 'Záznam byl úspěšně uložen',
      recordSaveFailed: 'Při ukládání záznamu došlo k chybě',
      optionsAreLoading: 'Možnosti se načítají...',
      optionsLoadFailed: 'Chyba stahování',
      noOptions: 'Žádná data',
      noData: 'Žádná data',
      actionSuccess: 'Akce byla úspěšně provedena',
      actionCanceled: 'Akce byl zrušena',
    },
    layout: {
      components: {
        aside: {
          menu: {
            applications: 'Další aplikace',
          },
        },
        detailSummary: {
          collapseTriggerOn: 'Více informací',
          collapseTriggerOff: 'Méně informací',
        },
        user: {
          logout: 'Odhlásit se',
        },
        monacoEditor: {
          loading: 'Editor se načítá...',
        },
        crashState: {
          title: 'Pád aplikace',
          body: 'Došlo k pádu aplikace, zkuste ji obnovit',
          refresh: 'Obnovit',
        },
        startup: {
          title: 'Aplikace se načítá',
        },
      },
    },
    actionBar: {
      moreActions: 'Další akce',
    },
    grid: {
      noResults: 'Žádné výsledky',
      actions: '',
      advancedFilters: {
        reset: 'Resetovat',
        use: 'Použít filtry',
      },
      selectedRows: {
        anyOnCurrentPage: {
          note: 'Jsou vybrány některé záznamy na této stránce ({itemCount}).',
          pickAllOnCurrentPage: 'Vybrat celou stránku',
          deselectAllRows: 'Zrušit výběr',
        },
        allOnCurrentPage: {
          note: 'Jsou vybrány všechny záznamy na této stránce ({itemCount}).',
          pickAll: 'Vybrat všechny záznamy',
          deselectAllRows: 'Zrušit výběr',
        },
        all: {
          note: 'Jsou vybrány všechny záznamy',
          pickAllOnCurrentPage: 'Vybrat pouze aktuální stránku',
          deselectAllRows: 'Zrušit výběr',
        },
      },
      bulkActions: {
        select: {
          placeholder: 'Vybrat hromadnou akci',
        },
      },
      export: {
        fileTypes: {
          csv: 'Export do CSV',
          xlsx: 'Export do XLSX',
        },
      },
    },
    form: {
      asyncInput: {
        loading: 'Políčko se načítá',
        error: 'Při načítání políčka došlo k chybě',
      },
      fileInput: {
        placeholder: 'Vyberte soubor',
        field: 'Nahrát soubor',
      },
      jsonInput: {
        helpModal: {
          title: 'Nápověda editoru',
          common: {
            heading: 'Obecné',
            text: 'Upravovat konfiguraci můžete pomocí integrovaného textového editoru. Editor nabízí základní nástroje pro jednodušší práci s JSON souberem.',
          },
          shortcuts: {
            heading: 'Klávesové zkratky',
            ctrlSpace: {
              keyBind: 'Ctrl + Mezerník',
              description: 'Zobrazení našeptávače přímo v místě, kde máte umístěný kurzor. Našeptávač zobrazí možnosti, které lze rychle zadat do dané části konfigurace,',
            },
            ctrlF: {
              keyBind: 'Ctrl + F',
              description: 'Zobrazení vyhledávací pole. Zadejte hledaný text a následně se můžete rychle přepínat mezi nalezenými částmi textu.',
            },
          },
        },
      },
    },
    schemaForms: {
      action: {
        collapseAll: 'Sbalit vše',
        expandAll: 'Rozbalit vše',
      },
      selectNode: 'Vyberte jednu ze sekcí',
      nodes: {
        suggestions: {
          example: 'Příklad',
        },
        inputString: {
          manualInput: 'Ruční zadání',
        },
        object: {
          placeholders: {
            add: 'Zadejte název atributu',
          },
        },
        array: {
          placeholders: {
            add: 'Zadejte název atributu',
          },
        },
        oneOf: {
          placeholders: {
            add: 'Zadejte název atributu',
          },
        },
        field: {
          booleanEnum: {
            true: 'Ano',
            false: 'Ne',
          },
        },
      },
      modals: {
        newNodeName: {
          message: 'Zadejte klíč nového atributu',
        },
        wrongNewNodeName: {
          message: 'Klíč atributu nemůže být prázdný',
        },
      },
    },
    validation: {
      required: 'Políčko "{field}" je povinné',
      invalidDateForm: 'Platný formát datumu',
      dateMin: 'Zadané datum nesmí být menší než {min}',
      dateMax: 'Zadané datum nesmí být větší než {max}',
      invalid: 'Políčko "{field}" má neplatnou hodnotu',
      invalidJson: 'Neplatný formát JSON',
      email: 'Zadaný email není platný',
      phone: 'Telefonní číslo musí začínat na předvolby +420, +421, +48, +43, +49, +38 nebo +41 a nesmí obsahovat mezery',
      file: {required: 'Soubor je povinný'},
      stringMin: 'Minimální délka je {min} znaků',
      stringMax: 'Maximální délka je {max} znaků',
      integer: 'Zadaná hodnota nesmí být desetinné číslo',
      positiveNumber: 'Zadaná hodnota není kladné číslo',
    },
    notFound: {
      title: 'Zákaznický systém Posigneo',
      subtitle: '404, stránku se nepodařilo nalézt',
      info: 'Nedaří se nám najít požadovanou stránku. Pokud jste nezadávali URL stránky do adresního řádku prohlížeče, máme pravděpodobně chybu v systému. Dejte nám o ní vědět.',
      link: 'Přejít na Dashboard',
    },
    unauthorized: {
      title: 'Zákaznický systém Posigneo',
      subtitle: 'Nemáte dostatečná oprávnění',
      info: 'Omlouváme se, ale požadovanou akci nemůžeme vykonat, protože na ní nemáte dostatečné oprávnění.',
      link: 'Přejít na Dashboard',
    },
  },
};
