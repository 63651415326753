import {
  axiosTokenInterceptor,
  axiosUnauthorizedInterceptor,
  axiosSentryRequestInterceptor,
} from '@api/helpers';
import defaultAxios from 'axios';
import {flow} from 'lodash-es';

export default flow([
  axiosTokenInterceptor,
  axiosUnauthorizedInterceptor,
  axiosSentryRequestInterceptor,
])(defaultAxios.create());
