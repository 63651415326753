export default {
  cs: {
    pointOfSaleConfiguration: {
      fields: {
        versionNumber: 'Verze',
        createdDate: 'Datum vytvoření',
        createdByName: 'Vytvořil/a',
        created: 'Vytvořeno',
        state: 'Stav',
        schemaId: 'Schéma',
        data: 'Konfigurace',
      },
      grid: {
        actions: {
          archive: {
            confirm: 'Opravdu chcete konfiguraci archivovat?',
          },
        },
      },
      form: {
        create: {
          title: 'Nová konfigurace',
        },
        update: {
          title: 'Editace konfigurace',
        },
        readonly: {
          title: 'Náhled konfigurace',
        },
        placeholders: {
          schemaId: 'Vyberte schéma',
        },
        actions: {
          switchToEdit: 'Přepnout do editace',
        },
      },
    },
  },
};
