import {
  ParseResult,
  useError,
} from '@ui/helpers/error';
import {emitTestEvent} from '@ui/helpers/testEvent';
import {useCoreStore} from '@ui/modules/Core/store/CoreStore';
import {InteractionLevel} from '@ui/modules/Core/types';
import {TestEvent} from '@ui/tests/e2e/helpers/testEvents';
import {isFunction} from 'lodash-es';
import {Ref, ref} from 'vue';
import {useI18n} from 'vue-i18n';

export default class Queue {
  queue: Array<{
    run: (...args: any[]) => Promise<any>,
    resolve: Function,
    reject: Function,
  }>;
  inProgress: boolean;

  constructor () {
    this.queue = [];
  }

  enqueue(run) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        run,
        resolve,
        reject,
      });
      this.dequeue();
    });
  }

  dequeue() {
    if (this.inProgress) {
      return;
    }

    const item = this.queue.shift();

    if (!item) {
      return;
    }

    try {
      this.inProgress = true;
      item.run()
        .then((value) => {
          this.inProgress = false;
          item.resolve(value);
          this.dequeue();
        })
        .catch((err) => {
          this.inProgress = false;
          item.reject(err);
          this.dequeue();
        });
    } catch (err) {
      this.inProgress = false;
      item.reject(err);
      this.dequeue();
    }
  }
}

export function useAsync<T extends(...args: any[]) => Promise<any>>(fn: T): T & {running: Ref<boolean>} {
  const running = ref(false);

  const execute = async (...args) => {
    if (running.value) {
      return;
    }

    try {
      running.value = true;
      return await fn(...args);
    } finally {
      running.value = false;
    }
  };

  Object.defineProperty(execute, 'running', {
    value: running,
  });

  return execute as T & {running: Ref<boolean>};
}

export function useAsyncWithInteractions<
  A extends any[],
  T extends(...args: A) => Promise<any>
>(
  fn: T,
  {
    onSuccess,
    onError,
    confirm,
    loading = true,
  }: {
    onSuccess?: string | (() => any),
    onError?: string | ((exception: ParseResult<Error>) => any),
    loading?: boolean,
    confirm?: string | ((...args: A) => string),
  } = {},
): T & {running: Ref<boolean>} {
  const running = ref(false);
  const i18n = useI18n();
  const coreStore = useCoreStore();
  const {parseException} = useError();

  const onSuccessFn = async () => {
    if (onSuccess === null) {
      return;
    }

    try {
      if (isFunction(onSuccess)) {
        await onSuccess();
        return;
      }

      /**
       * Do not await, for toast it is not necessary
       */
      coreStore.toast(onSuccess ?? i18n.t('common.actionSuccess'));
    } finally {
      emitTestEvent(TestEvent.interactionResultPositive);
    }
  };

  const onErrorFn = async (e) => {
    /**
     * TODO: Sentry (sentryRecordException) ??
     */

    if (onError === null) {
      return;
    }

    try {
      if (isFunction(onError)) {
        await onError(parseException(e));
        return;
      }

      await coreStore.alert(onError ?? parseException(e).message, {type: InteractionLevel.error});
    } finally {
      emitTestEvent(TestEvent.interactionResultNegative);
    }
  };

  const onConfirmFn = async (...args: A): Promise<boolean> => {
    if (!confirm) {
      return true;
    }

    if (isFunction(confirm)) {
      return await coreStore.confirm(confirm(...args));
    }

    return await coreStore.confirm(confirm);
  };

  const ensureLoading = async (fn) => {
    if (loading) {
      try {
        coreStore.setLoader(true);
        return await fn();
      } finally {
        coreStore.setLoader(false);
      }
    } else {
      return await fn();
    }
  };

  const execute = async (...args: A) => {
    if (running.value) {
      return;
    }

    try {
      running.value = true;

      if (!(await onConfirmFn(...args))) {
        return;
      }

      const result = await ensureLoading(() => fn(...args));

      await onSuccessFn();

      return result;
    } catch (e) {
      await onErrorFn(e);
      throw e;
    } finally {
      running.value = false;
    }
  };

  Object.defineProperty(execute, 'running', {
    value: running,
  });

  return execute as T & {running: Ref<boolean>};
}
