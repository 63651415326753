/**
 * Parse hostname from <meta name="metaName"> content="http://example.com" />
 */
export function getMetaHostname(metaName: string): string {
  try {
    return new URL(getMetaTagContent(metaName)).hostname;
  } catch (err) {
    return null;
  }
}


export function getMetaTagContent(metaName: string): string {
  try {
    return (<HTMLMetaElement>document.querySelector(`meta[name="${metaName}"]`)).content;
  } catch (err) {
    console.warn(`Failed to parse meta header ${metaName}`, metaName);
  }
  return null;
}
