import {PointOfSaleSynchronizationType} from '@api/posMiddlewareCore/constants/pointOfSaleSynchronizationType';
import {SyncedStatus} from '@api/posMiddlewareCore/constants/pointOfSaleSyncStatus';

export default {
  cs: {
    pointOfSaleSynchronization: {
      fields: {
        titleKey: 'Oblast',
        titleKeyEnum: {
          [PointOfSaleSynchronizationType.articles]: 'Artikly',
          [PointOfSaleSynchronizationType.promotions]: 'Promoce',
          [PointOfSaleSynchronizationType.posData]: 'Základní data POS',
          [PointOfSaleSynchronizationType.configurations]: 'Konfigurace',
          [PointOfSaleSynchronizationType.cashiers]: 'Pokladní',
          noData: 'Žádná data',
        },
        isUpToDate: 'Stav',
        isUpToDateEnum: {
          true: 'Aktuální',
          false: 'Neaktuální',
        },
        lastAppliedAt: 'Poslední data POS',
        nonSyncedRequestedAt: 'Staženo na POS',
        nonSyncedCreatedAt: 'Poslední data z MW',
        isSynced: 'Stav',
        isSyncedEnum: {
          [SyncedStatus.all]: 'Data jsou aktuální',
          [SyncedStatus.some]: 'Některá data nejsou aktuální',
          [SyncedStatus.none]: 'Žádná data nejsou aktuální',
        },
      },
    },
  },
};
