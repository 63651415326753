import VoucherEmissionSmallDto from '@api/posMiddlewareCore/entity/VoucherEmissionSmallDto';
import VoucherState from '@api/posMiddlewareCore/entity/VoucherState';
import DateTimeType from '@api/posMiddlewareCore/types/DateTimeType';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import NumberType from '@api/posMiddlewareCore/types/NumberType';
import StringType from '@api/posMiddlewareCore/types/StringType';

export default {
  id: {
    type: new StringType(),
  },
  voucherNumber: {
    type: new StringType(),
  },
  validFrom: {
    type: new DateTimeType(),
  },
  validTill: {
    type: new DateTimeType(),
  },
  state: {
    type: new EntityType(VoucherState),
  },
  usedAt: {
    type: new DateTimeType(),
  },
  usedPlace: {
    type: new StringType(),
  },
  emission: {
    type: new EntityType(VoucherEmissionSmallDto),
  },
  maxUsageCount: {
    type: new NumberType(),
  },
  usageCount: {
    type: new NumberType(),
  },
};
