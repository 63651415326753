
import ModalConfirmContent from '@ui/components/modal/ModalConfirmContent.vue';
import {defineComponent} from 'vue';
import {useCoreStore} from '../../store/CoreStore';
import {useConfirmContent} from './content';

export default defineComponent({
  name: 'ModalConfirmContentDefault',
  components: {ModalConfirmContent},
  setup() {
    const coreStore = useCoreStore();
    const {
      type,
      hasRejectFn,
      onReject,
      onConfirm,
    } = useConfirmContent();

    return {
      type,
      hasRejectFn,
      onReject,
      onConfirm,
      coreStore,
    };
  },
});
