import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionWrapper = _resolveComponent("TransitionWrapper")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#notifications" }, [
    _createVNode(_component_TransitionWrapper, {
      enterTime: _ctx.enterTime,
      leaveTime: _ctx.leaveTime,
      isVisible: _ctx.open,
      onEntered: _ctx.notificationOpened,
      onLeft: _ctx.notificationClosed
    }, {
      default: _withCtx(({entering, leaving, entered}) => [
        _createElementVNode("div", {
          class: "notification-container",
          style: _normalizeStyle({
            zIndex: _ctx.totalZIndex
          })
        }, [
          _createElementVNode("div", _mergeProps({ class: "notification" }, _ctx.$attrs), [
            _createElementVNode("div", {
              class: _normalizeClass(["notification-content opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95", [
                {
                  ['ease-out duration-300']: entering,
                  ['ease-in duration-200']: leaving,
                  ['opacity-100 translate-y-0 sm:scale-100']: (entered || entering) && !leaving,
                }
              ]]),
              style: _normalizeStyle({
                ...(_ctx.offsetNotificationContentX ? {
                  '--tw-translate-x': _ctx.offsetNotificationContentX,
                } : {}),
                ...(_ctx.offsetNotificationContentY ? {
                  '--tw-translate-y': _ctx.offsetNotificationContentY,
                } : {})
              }),
              role: "alertdialog",
              "aria-modal": "true",
              "aria-labelledby": "notification-headline",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
            }, [
              _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({entering, leaving, entered})))
            ], 6)
          ], 16)
        ], 4)
      ]),
      _: 3
    }, 8, ["enterTime", "leaveTime", "isVisible", "onEntered", "onLeft"])
  ]))
}