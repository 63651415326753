export enum RedirectPathName {
  redirectLogin = '/redirect-login',
  redirectLogout = '/redirect-logout',
  redirectLoginCallback = '/redirect-login-callback',
}

export function isLoginRedirectPathName(pathname: string) {
  return [
    RedirectPathName.redirectLogin,
    RedirectPathName.redirectLoginCallback,
  ].includes(pathname as RedirectPathName);
}

export function createAuthCallbackRedirect() {
  try {
    const callbackUrl = new URL(window.location.href);

    if (isLoginRedirectPathName(callbackUrl.pathname)) {
      return encodeURIComponent(window.location.origin);
    }
  } catch (e) {
    console.error(e);
    return encodeURIComponent(window.location.origin);
  }

  return encodeURIComponent(window.location.href);
}
