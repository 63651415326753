
import {HLInputSelect} from '@designeo/vue-forms/src/Input/InputSelect';
import {ComputePositionConfig} from '@floating-ui/dom/src/types';
import {useDropdown} from '@ui/components/headless/Dropdown';
import {inputEvents, inputProps} from '@ui/components/input/input';
import {useInput} from '@ui/helpers/form';
import {
  find,
} from 'lodash-es';
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  watch,
} from 'vue';
import Button from '../button/Button.vue';
import {HLPropWatcher} from '../headless/Watcher';
import PhIcon from '../PhIcon.vue';

export default defineComponent({
  name: 'InputSelect',
  components: {
    Button,
    HLPropWatcher,
    PhIcon,
    HLInputSelect,
  },
  inheritAttrs: false,
  props: {
    ...inputProps,
    sortBy: {
      type: Function,
      required: false,
      default: (arr) => arr,
    },
    options: {
      type: Array as PropType<Array<{ id: any, label: string, disabled?: boolean, selected?: boolean}>>,
      required: true,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    placementOptions: {
      type: Object as PropType<ComputePositionConfig>,
      required: false,
      default: () => ({}),
    },
  },
  emits: [...inputEvents, 'opened', 'closed'],
  setup(props, {emit}) {
    const {
      createDropdown,
      destroyDropdown,
      triggerRef,
      dropdownRef,
    } = useDropdown(props.placementOptions);

    const {
      valid,
      field,
      disabled,
      computedDisabled,
    } = useInput(props);

    const innerModel = computed({
      get: () => {
        return props.modelValue;
      },
      set: (val) => {
        emit('update:modelValue', val);
      },
    });

    const onIsOpenedUpdate = async ([isOpened]) => {
      if (isOpened) {
        await nextTick();
        await createDropdown();
        emit('opened');
      } else {
        destroyDropdown();
      }
    };

    const withFocusOut = ($event, callback) => {
      if (!$event.bubbles) {
        return;
      }

      if ($event.target.parentElement.contains($event.relatedTarget)) {
        return;
      }

      return callback();
    };

    const withFocusIn = ($event, callback) => {
      if (computedDisabled.value) {
        return;
      }

      if (!$event.bubbles && ($event.relatedTarget?.parentElement?.contains?.($event.target) ?? true)) {
        return;
      }

      if (!($event.relatedTarget?.parentElement?.contains?.($event.target) ?? true)) {
        return callback();
      }

      return callback();
    };

    watch(() => props.options, () => {
      if (innerModel.value) {
        return;
      }

      const selectedOption = find(props.options, {selected: true});

      if (selectedOption) {
        innerModel.value = selectedOption.id;
      }
    });

    return {
      onIsOpenedUpdate,
      triggerRef,
      dropdownRef,
      innerModel,
      valid,
      field,
      withFocusIn,
      disabled,
      computedDisabled,
      withFocusOut,
    };
  },
});
