import {onBeforeUnmount, onMounted} from 'vue';

export function createEmitterReaction<E extends any>(emitter) {
  return (event: E, callback: (...args: any[]) => void, {once = false} = {}) => {
    let canceled = false;

    const cancelListener = () => {
      if (canceled) {
        return;
      }

      canceled = true;
      emitter.removeEventListener(event as string, callback);
    };

    onMounted(() => {
      emitter.addEventListener(event as string, callback, {once});
    });

    onBeforeUnmount(() => {
      cancelListener();
    });

    return cancelListener;
  };
}
