export default {
  cs: {
    pointOfSaleAttribute: {
      grid: {
        title: 'POS Atributy',
        filters: {
          search: 'Vyhledat',
        },
        actions: {
          create: {
            title: 'Založit atribut',
          },
        },
      },
      form: {
        create: {
          title: 'Založit POS atribut',
        },
        update: {
          title: 'Editovat POS atribut {name}',
        },
        assign: {
          title: 'Úprava přiřazených atributů',
        },
        placeholders: {
          name: 'Zadejte název atributu',
          attributes: 'Vyberte atributy',
          attribute: 'Vyberte atribut',
          validFrom: 'Vyberte platnost od',
          validTill: 'Vyberte platnost do',
        },
        actions: {
          changeValidity: {
            title: 'Změnit platnost',
          },
        },
      },
      fields: {
        validFrom: 'Platnost od',
        validTill: 'Platnost do',
        attribute: 'Atribut',
        name: 'Název',
      },
    },
  },
};
