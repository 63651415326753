import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-center typo-body-1 tracking-wide px-2 py-2", [_ctx.isSubitem ? 'font-base' : 'font-medium']])
  }, [
    _createVNode(_component_router_link, _normalizeProps({
      class: ["relative px-3 py-3 rounded-lg hidden lg:flex flex-row flex-grow gap-4 items-center justify-start transition duration-200 hover:bg-light-gray", {
        'bg-light-gray': _ctx.item.isActive,
        'justify-center': _ctx.collapsed,
      }],
      title: _ctx.item.label,
      to: _ctx.item.to,
      [(_ctx.$test.asideMenuItemLink) || ""]: _ctx.item.to.name
    }), {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_ctx.item.icon && !_ctx.isSubitem)
            ? (_openBlock(), _createBlock(_component_PhIcon, {
                key: 0,
                icon: _ctx.item.icon,
                size: '5xl',
                class: _normalizeClass({
            'text-gray-600': !_ctx.item.isActive,
            'text-green': _ctx.item.isActive,
          })
              }, null, 8, ["icon", "class"]))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.collapsed && !_ctx.collapsedAfterAnimation)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["text-left", {
          'text-gray-600': !_ctx.item.isActive,
          'text-green': _ctx.item.isActive,
          'pl-7': _ctx.isSubitem,
        }])
            }, _toDisplayString(_ctx.item.label), 3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 16, ["class", "title", "to"]),
    (!_ctx.collapsed)
      ? (_openBlock(), _createBlock(_component_router_link, _normalizeProps({
          key: 0,
          class: ["relative px-3 py-3 rounded-lg lg:hidden flex flex-row flex-grow gap-4 items-center justify-start transition duration-200 hover:bg-light-gray", {
        'bg-light-gray': _ctx.item.isActive,
        'justify-center': _ctx.collapsed,
      }],
          to: _ctx.item.to,
          [(_ctx.$test.asideMenuItemLink) || ""]: _ctx.item.to.name,
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.collapsed = true;})
        }), {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              (_ctx.item.icon && !_ctx.isSubitem)
                ? (_openBlock(), _createBlock(_component_PhIcon, {
                    key: 0,
                    icon: _ctx.item.icon,
                    size: '5xl',
                    class: _normalizeClass({
            'text-gray-600': !_ctx.item.isActive,
            'text-green': _ctx.item.isActive,
          })
                  }, null, 8, ["icon", "class"]))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.collapsed && !_ctx.collapsedAfterAnimation)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["text-left", {
          'text-gray-600': !_ctx.item.isActive,
          'text-green': _ctx.item.isActive,
          'pl-7': _ctx.isSubitem,
        }])
                }, _toDisplayString(_ctx.item.label), 3))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 16, ["class", "to"]))
      : _createCommentVNode("", true)
  ], 2))
}