
import Modal from '@ui/components/modal/Modal.vue';
import {ZLayer} from '@ui/constants/zLayer';
import {
  computed,
  defineComponent,
} from 'vue';
import {useCoreStore} from '../store/CoreStore';
import {ConfirmTemplates} from '../types';
import {useConfirmContent} from './contents/content';
import ModalConfirmContentDefault from './contents/ModalConfirmContentDefault.vue';

export default defineComponent({
  name: 'ModalConfirm',
  components: {
    Modal,
  },
  setup() {
    const coreStore = useCoreStore();
    const {onReject, onConfirm} = useConfirmContent();

    const zIndex = computed(() => {
      return ZLayer.critical;
    });

    const component = computed(() => {
      if (coreStore.confirmContent.value?.component) {
        return coreStore.confirmContent.value?.component;
      }
      switch (coreStore.confirmContent.value?.template) {
        case ConfirmTemplates.default: return ModalConfirmContentDefault;
        default: return ModalConfirmContentDefault;
      }
    });

    const componentOptions = computed(() => {
      if (coreStore.confirmContent.value?.component) {
        return coreStore.confirmContent.value?.componentOptions;
      }
      return {};
    });

    return {
      onReject,
      onConfirm,
      coreStore,
      component,
      componentOptions,
      zIndex,
    };
  },
});
