import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/promotion-lists',
    name: 'promotion-lists',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.promotionListList,
    },
  },
  {
    path: '/promotion-list/create',
    name: 'promotion-list-create',
    component: () => import('./Form/CreatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.promotionListCreate,
    },
  },
  {
    path: '/promotion-list/update/:id',
    name: 'promotion-list-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
] as RegisterRoutesOptions;
