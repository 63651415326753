
import {InteractionLevel} from '@ui/modules/Core/types';
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

export default defineComponent({
  name: 'ModalDetachedBody',
  props: {
    type: {
      type: String as PropType<InteractionLevel>,
      required: false,
      default: InteractionLevel.default,
    },
  },
  setup(props) {
    const typeClasses = computed(() => {
      switch (props.type) {
        case InteractionLevel.error:
          return 'text-red';
        case InteractionLevel.warning:
          return 'text-orange';
        case InteractionLevel.info:
          return 'text-blue';
        case InteractionLevel.success:
          return 'text-green';
        case InteractionLevel.default:
          return null;
        default: return null;
      }
    });


    return {
      typeClasses,
    };
  },
});
