import {createEntity} from '@designeo/apibundle-js';
import {isNil} from 'lodash-es';
import VoucherPreviewDtoConfig from './VoucherPreviewDto.config';

export default class VoucherPreviewDto extends createEntity(VoucherPreviewDtoConfig) {
  get usageCountWithMax() {
    if (isNil(this.maxUsageCount)) {
      return `${this.usageCount}`;
    }

    return `${this.usageCount}/${this.maxUsageCount}`;
  }
}
