
import {
  computed,
  defineComponent,
  PropType,
  shallowRef,
  watch,
} from 'vue';
import {
  getPhIcon,
  PhIcons,
  PhIconSizes,
} from '../helpers/phIcons';

export default defineComponent({
  name: 'PhIcon',
  inheritAttrs: false,
  props: {
    icon: {
      type: String as (PropType<PhIcons> | PropType<string>),
      required: true,
    },
    size: {
      type: null as (PropType<PhIconSizes>),
      required: false,
      default: 'sm',
    },
  },
  setup(props) {
    const component = shallowRef(null);

    const fetchIcon = async (icon) => {
      component.value = await getPhIcon(icon);
    };

    const sizeFromCssVariable = computed(() => {
      if (typeof props.size === 'number') {
        return props.size;
      }

      return getComputedStyle(document.documentElement)
        .getPropertyValue(`--icon-size-${props.size}`);
    });

    watch(() => props.icon, () => {
      fetchIcon(props.icon ?? 'n/a');
    }, {immediate: true});


    return {
      component,
      sizeFromCssVariable,
    };
  },
});
