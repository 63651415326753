import {useEnumStore} from '@middleware-ui/modules/AppCore/store/EnumStore';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  appId: any,
  preloadHooks: any[],
}

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerAppCoreModule(ctx: RequiredContext) {
  const enumStore = useEnumStore();
  await enumStore.fetchEnums();

  await ctx.registerTranslations(translations);
}
