
import {draf} from '@designeo/js-helpers';
import Notification from '@ui/components/notification/Notification.vue';
import {InteractionLevel} from '@ui/modules/Core/types';
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import {useCoreStore} from '../store/CoreStore';

export default defineComponent({
  name: 'NotificationToast',
  components: {
    Notification,
  },
  setup() {
    const coreStore = useCoreStore();
    const interval = ref(null);
    const toastRef = ref(null);
    const ticks = ref(0);
    const tickRate = 500;

    const countdownStart = () => {
      countdownEnd();
      interval.value = setInterval(() => {
        ticks.value = ticks.value + tickRate;
        if (ticks.value >= coreStore.toastContent.value.displayTime) {
          onClose();
        }
      }, tickRate);
    };

    const countdownEnd = () => {
      ticks.value = 0;
      clearInterval(interval.value);
    };

    const content = computed(() => {
      return coreStore.toastContent.value?.message;
    });

    const displayTime = computed(() => {
      return coreStore.toastContent.value?.displayTime;
    });

    const refreshAnimation = async () => {
      const el: HTMLDivElement = toastRef.value;
      el.classList.remove('animate-background');
      await draf(null);
      el.classList.add('animate-background');
    };

    const countdown = computed(() => {
      if (!coreStore.toastContent.value) {
        return null;
      }

      return (ticks.value / coreStore.toastContent.value.displayTime) * 100;
    });

    watch(() => coreStore.toastContent.value, (content, oldContent) => {
      if (oldContent && content) {
        refreshAnimation();
      }

      if (content) {
        countdownStart();
      } else {
        countdownEnd();
      }
    });

    const createInteractionLevelStyles = (colorVar) => ({
      background: `linear-gradient(-90deg, rgba(var(${colorVar}), .4) 0%, rgba(var(${colorVar}), .2) 40%, #fff 55%)`,
      backgroundSize: '300% 100%',
      animationDuration: `${displayTime.value + 300}ms`,
    });


    const interactionLevelOptions = computed(() => {
      switch (coreStore.toastContent.value?.type) {
        case InteractionLevel.error:
          return {
            classes: 'text-red bg-red border-red',
            styles: createInteractionLevelStyles('--color-red'),
          };
        case InteractionLevel.warning:
          return {
            classes: 'text-orange bg-orange border-orange',
            styles: createInteractionLevelStyles('--color-orange'),
          };
        case InteractionLevel.info:
          return {
            classes: 'text-blue bg-blue border-blue',
            styles: createInteractionLevelStyles('--color-blue'),
          };
        case InteractionLevel.success:
        case InteractionLevel.default:
          return {
            classes: 'text-green bg-green border-green',
            styles: createInteractionLevelStyles('--color-green'),
          };
        default: return null;
      }
    });

    const onClose = () => {
      countdownEnd();
      coreStore.toastContent.value?.resolve?.();
    };

    return {
      onClose,
      coreStore,
      content,
      displayTime,
      interactionLevelOptions,
      countdown,
      toastRef,
    };
  },
});
