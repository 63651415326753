export function useAppTools () {
  const setTitle = (title) => {
    document.title = title;
  };


  return {
    setTitle,
  };
}
