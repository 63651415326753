import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "flex flex-row flex-grow gap-4 items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_AsideMenuItem = _resolveComponent("AsideMenuItem")!
  const _component_HLDropdown = _resolveComponent("HLDropdown")!
  const _component_HLPhIconFallback = _resolveComponent("HLPhIconFallback")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.collapsed)
    ? (_openBlock(), _createBlock(_component_HLDropdown, { key: 0 }, {
        default: _withCtx(({
      triggerRef,
      dropdownRef,
      isActive,
      toggle,
    }) => [
          _createElementVNode("div", {
            ref: (el) => triggerRef.value = el,
            class: "py-2 px-2 flex flex-row justify-between items-center"
          }, [
            _createElementVNode("button", _normalizeProps({
              class: "py-3 px-3 text-gray-600 text-center typo-body-1 flex flex-row flex-grow items-center font-medium tracking-wide appearance-none",
              [(_ctx.$test.asideMenuItemLink) || ""]: _ctx.group.group,
              [(_ctx.$test.asideMenuItemIsActiveGroup) || ""]: _ctx.group.isActive,
              onClick: toggle
            }), [
              _createElementVNode("span", _hoisted_2, [
                _createElementVNode("span", null, [
                  (_ctx.group.icon)
                    ? (_openBlock(), _createBlock(_component_PhIcon, {
                        key: 0,
                        icon: _ctx.group.icon,
                        size: '5xl'
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
                ]),
                (!_ctx.collapsed && !_ctx.collapsedAfterAnimation)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.group.label), 1))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.collapsed)
                ? (_openBlock(), _createBlock(_component_PhIcon, {
                    key: 0,
                    icon: "PhCaretDown",
                    size: "lg",
                    class: _normalizeClass(["transform transition-all justify-self-end", {
            'rotate-180': isActive.value || _ctx.group.isActive
          }])
                  }, null, 8, ["class"]))
                : _createCommentVNode("", true)
            ], 16, _hoisted_1)
          ], 512),
          (isActive.value || _ctx.group.isActive)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                ref: (el) => dropdownRef.value = el
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.items, (item, itemIndex) => {
                  return (_openBlock(), _createBlock(_component_AsideMenuItem, {
                    key: itemIndex,
                    item: item.asItem,
                    isSubitem: ""
                  }, null, 8, ["item"]))
                }), 128))
              ], 512))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : (_ctx.collapsed)
      ? (_openBlock(), _createBlock(_component_HLDropdown, {
          key: 1,
          placement: "right"
        }, {
          default: _withCtx(({
      triggerRef,
      dropdownRef,
      isActive,
      toggle,
    }) => [
            _createElementVNode("div", _mergeProps(_ctx.$attrs, {
              ref: (el) => triggerRef.value = el,
              class: "py-2 px-2 flex flex-row justify-center items-center"
            }), [
              _createElementVNode("button", {
                class: _normalizeClass(["appearance-none text-center tracking-wide font-medium uppercase p-3 flex flex-row flex-grow justify-center items-center cursor-pointer transition duration-200 hover:bg-light-gray rounded", [{'bg-light-gray': isActive.value && !_ctx.group.isActive}]]),
                title: _ctx.group.label,
                onClick: toggle
              }, [
                (_ctx.group.icon)
                  ? (_openBlock(), _createBlock(_component_PhIcon, {
                      key: 0,
                      icon: _ctx.group.icon,
                      size: '5xl',
                      class: _normalizeClass({
            'text-green': _ctx.group.isActive,
            'text-gray-600': !_ctx.group.isActive,
          })
                    }, null, 8, ["icon", "class"]))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_4),
              (isActive.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    ref: (el) => dropdownRef.value = el,
                    class: "lg:absolute bg-white rounded-lg border border-gray-200 p-2 text-darkest-gray flex flex-col gap-2",
                    style: {"min-width":"250px"}
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.items, (item, itemIndex) => {
                      return (_openBlock(), _createBlock(_component_router_link, _normalizeProps({
                        key: itemIndex,
                        class: ["flex flex-row items-center gap-4 cursor-pointer px-3 py-3 rounded-lg transition duration-200 hover:bg-light-gray font-medium", {
            'bg-light-gray text-green': item.isActive,
            'text-gray-600': !item.isActive,
          }],
                        to: item.asItem.to,
                        [(_ctx.$test.asideMenuItemLink) || ""]: item.asItem.to.name
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_HLPhIconFallback, {
                            icon: item.icon,
                            fallbackIcon: "PhArrowSquareOut"
                          }, {
                            default: _withCtx(({icon}) => [
                              _createVNode(_component_PhIcon, {
                                icon: icon,
                                size: '5xl'
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["icon"]),
                          _createElementVNode("div", null, _toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1040, ["class", "to"]))
                    }), 128))
                  ], 512))
                : _createCommentVNode("", true)
            ], 16)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}