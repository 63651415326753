import ErrorResponse from '@api/posMiddlewareCore/entity/ErrorResponse';
import PosEnumsDto from '@api/posMiddlewareCore/entity/PosEnumsDto';
import EntityType from '@api/posMiddlewareCore/types/EntityType';
import URLQueryParamsType from '@api/posMiddlewareCore/types/URLQueryParamsType';
import VoidType from '@api/posMiddlewareCore/types/VoidType';
import axios from '../axios';

export default {
  name: 'api.v1.admin.enums',
  method: <'GET'>'GET',
  path: '/api/v1/admin/enums',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(PosEnumsDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new URLQueryParamsType<{ }>(),
  axios,
};
