
import {HLInputText} from '@designeo/vue-forms';
import {inputEvents, inputProps} from '@ui/components/input/input';
import {useInput} from '@ui/helpers/form';
import {
  computed,
  defineComponent,
  ref,
} from 'vue';

export default defineComponent({
  name: 'InputString',
  components: {
    HLInputText,
  },
  inheritAttrs: false,
  props: {
    ...inputProps,
  },
  emits: [...inputEvents],
  setup(props, {emit}) {
    const inputRef = ref(null);
    const model = computed({
      get() {
        return props.modelValue;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const {
      valid,
      field,
      disabled,
      computedDisabled,
      computedReadonly,
    } = useInput(props);

    return {
      inputRef,
      model,
      valid,
      field,
      disabled,
      computedDisabled,
      computedReadonly,
    };
  },
});
