
import {HLDropdown} from '@ui/components/headless/Dropdown';
import PhIcon from '@ui/components/PhIcon.vue';
import {injectAsideContext} from '@ui/helpers/aside';
import {MenuItemTypeDropdown} from '@ui/helpers/menu';
import {defineComponent, PropType} from 'vue';

export default defineComponent({
  name: 'AsideMenuDropdown',
  components: {HLDropdown, PhIcon},
  props: {
    dropdown: {
      type: Object as PropType<MenuItemTypeDropdown>,
      required: true,
    },
  },
  setup() {
    const {collapsed, collapsedAfterAnimation} = injectAsideContext();

    return {
      collapsed,
      collapsedAfterAnimation,
    };
  },
});
