import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {KeyboardKey} from '@ui/constants/keyboardKey';
import {ZLayer} from '@ui/constants/zLayer';
import {guid} from '@ui/helpers/guid';
import {Canceler} from 'axios';
import {
  isNil,
  map,
  reject,
  last,
  orderBy,
  first,
} from 'lodash-es';
import {
  ConfirmTemplates,
  InteractionLevel,
  IModal,
  INotification,
} from '../types';

type SimpleObject = {[key: string]: any};

export interface ICoreStore {
  apiRequestCancelers: Map<string, Canceler>,
  keyboardBufferMute: {
    state: number,
  },
  modalStack: IModal[],
  notificationStack: INotification[],
  loader: {
    state: number,
    opacity: number,
    timeout: ReturnType<typeof setTimeout>,
    timeoutExpired: boolean,
    timeoutDisabled: boolean,
  },
  confirm: {
    message: string,
    resolve: (value?: boolean) => void,
    reject?: (reason?: any) => void,
    type: InteractionLevel,
    template: ConfirmTemplates,
    component?: any,
    componentOptions?: SimpleObject,
  },
  toast: {
    message: string,
    resolve: (value?: boolean) => void,
    reject?: (reason?: any) => void,
    type: InteractionLevel,
    displayTime: number,
  },
}


const createInitState = () => ({
  apiRequestCancelers: new Map(),
  keyboardBufferMute: {
    state: 0,
  },
  modalStack: [],
  notificationStack: [],
  loader: {
    state: 0,
    opacity: null,
    timeout: null,
    timeoutExpired: false,
    timeoutDisabled: false,
  },
  confirm: null,
  toast: null,
});

export class CoreStore extends Store<ICoreStore> {
  constructor() {
    super(createInitState());

    this.setupModalListeners();
  }

  apiRequestCancelers = getter(() => {
    return Array.from(this.state.apiRequestCancelers.entries());
  });

  setApiRequestCanceler = action((key: string, canceler) => {
    this.state.apiRequestCancelers.set(key, canceler);
  });

  getApiRequestCanceler = action((key) => {
    this.state.apiRequestCancelers.get(key);
  });

  clearApiRequestCanceler = action((key: string) => {
    this.state.apiRequestCancelers.delete(key);
  });

  setKeyboardBufferMute = action((state: boolean) => {
    this.state.keyboardBufferMute.state = this.state.keyboardBufferMute.state + (state ? 1 : -1);
  });

  setLoader = action((
    state: boolean,
    {
      opacity = this.state.loader.opacity ?? this.defaultLoaderOpacity.value,
      timeout = this.defaultLoaderTimeout.value,
    } = {},
  ) => {
    this.state.loader.state = this.state.loader.state + (state ? 1 : -1);

    if (!this.loaderActive.value) { // reset
      this.state.loader.opacity = this.defaultLoaderOpacity.value;
      clearTimeout(this.state.loader.timeout);
      this.state.loader.timeoutExpired = false;
      this.state.loader.timeoutDisabled = false;
    } else {
      this.state.loader.opacity = opacity;
      clearTimeout(this.state.loader.timeout);

      if (this.state.loader.timeoutDisabled) {
        timeout = null;
      }

      if (isNil(timeout)) {
        this.state.loader.timeoutDisabled = true;
      } else {
        this.state.loader.timeout = setTimeout(() => {
          this.state.loader.timeoutExpired = true;
        }, timeout);
      }
    }
  });

  modalStackInZOrder = getter(() => {
    return orderBy(this.state.modalStack, ({zIndexBase, zIndexOffset}) => zIndexBase + zIndexOffset);
  });

  notificationStackInZOrder = getter(() => {
    return orderBy(this.state.notificationStack, ({zIndexBase, zIndexOffset}) => zIndexBase + zIndexOffset);
  });

  topModal = getter(() => {
    return last(this.modalStackInZOrder.value);
  });

  bottomNotification = getter(() => {
    return first(this.notificationStackInZOrder.value);
  });

  requestModal = action((closeCallback: IModal['closeCallback'], {
    id = guid(),
    zIndexBase = ZLayer.modal,
    silenceCloseListeners = false,
    spawnedAtRoute,
  }: Partial<IModal> = {}) => {
    const zIndexOffset = Math.max(...map(this.state.modalStack, (modal) => modal.zIndexOffset).concat(0)) + 1;
    const newModal: IModal = {
      id,
      silenceCloseListeners,
      zIndexBase,
      zIndexOffset,
      closeCallback,
      spawnedAtRoute,
    };
    this.state.modalStack.push(newModal);
    return newModal;
  });

  requestNotification = action((closeCallback: INotification['closeCallback'], {
    id = guid(),
    zIndexBase = ZLayer.modal,
  }: Partial<IModal> = {}) => {
    const zIndexOffset = Math.max(...map(this.state.notificationStack, (modal) => modal.zIndexOffset).concat(0)) + 1;
    const newNotification: INotification = {
      id,
      zIndexBase,
      zIndexOffset,
      closeCallback,
    };
    this.state.notificationStack.push(newNotification);
    return newNotification;
  });

  updateModal = action((id: string, {
    zIndexBase,
  }: {
    zIndexBase?: number,
  }) => {
    const modal = this.state.modalStack.find(({id: modalId}) => modalId === id);
    if (modal) {
      modal.zIndexBase = zIndexBase ?? modal.zIndexBase;
    }
  });

  updateNotification = action((id: string, {
    zIndexBase,
  }: {
    zIndexBase?: number,
  }) => {
    const notification = this.state.notificationStack.find(({id: notificationId}) => notificationId === id);
    if (notification) {
      notification.zIndexBase = zIndexBase ?? notification.zIndexBase;
    }
  });

  removeModal = action((id: string) => {
    this.state.modalStack = reject(this.state.modalStack, ({id: modalId}) => modalId === id);
  });

  removeNotification = action((id: string) => {
    this.state.notificationStack = reject(
      this.state.notificationStack,
      ({id: notificationId}) => notificationId === id,
    );
  });

  closeTopModal = action(async (state: boolean = false) => {
    const topModal = this.topModal.value;
    if (topModal) {
      this.topModal.value?.closeCallback(state);
      return true;
    } else {
      return false;
    }
  });

  closeBottomNotification = action(async () => {
    const bottomNotification = this.bottomNotification.value;
    if (bottomNotification) {
      this.bottomNotification.value?.closeCallback();
      return true;
    } else {
      return false;
    }
  });

  setupModalListeners = action(() => {
    let wasOpenedOnKeyDown = false;

    const isEventTypeClose = (event: KeyboardEvent) => {
      if (this.topModal.value?.silenceCloseListeners ?? false) {
        return false;
      }

      if (event.code === KeyboardKey.Escape) {
        return true;
      }

      if (event.code === KeyboardKey.Enter) {
        return true;
      }

      return false;
    };

    const onKeyDown = async (event: KeyboardEvent) => {
      if (!isEventTypeClose(event)) {
        return;
      }

      if (this.topModal.value) {
        event.preventDefault();
        wasOpenedOnKeyDown = true;
      }
    };

    const onKeyUp = async (event: KeyboardEvent) => {
      if (!isEventTypeClose(event)) {
        return;
      }
      /**
       * we want to execute this as last in keyboard buffers pipeline
       */
      // await wait(50)(null);

      if (this.topModal.value) {
        event.preventDefault();
      }

      if (wasOpenedOnKeyDown) {
        this.closeTopModal(event.code === KeyboardKey.Enter);
        wasOpenedOnKeyDown = false;
      }
    };

    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keydown', onKeyDown);
  });

  resetState = action(() => {
    this.state = Object.assign(this.state, createInitState());
  });

  keyboardBufferMuteActive = getter(() => {
    return this.state.keyboardBufferMute.state !== 0;
  });

  loaderActive = getter(() => {
    return this.state.loader.state !== 0;
  });

  loaderOpacity = getter(() => {
    return this.state.loader.opacity * 100;
  });

  defaultLoaderOpacity = getter(() => {
    return 0.4;
  });

  defaultLoaderTimeout = getter(() => {
    return 30 * 1000;
  });

  loaderTimeoutExpired = getter(() => {
    return this.state.loader.timeoutExpired;
  });

  confirmContent = getter(() => this.state.confirm);

  toastContent = getter(() => this.state.toast);

  alert = action((message, {
    type = InteractionLevel.default,
    template = ConfirmTemplates.default,
  }: {
    type?: InteractionLevel,
    template?: ConfirmTemplates,
  } = {}) => {
    let resolve = null;

    const promise = (new Promise((onFulfilled, onRejected) => {
      resolve = () => onFulfilled(true);
    }))
      .then((result) => {
        this.state.confirm = null;
        return result;
      })
      .catch((err) => {
        this.state.confirm = null;
        throw err;
      });


    this.state.confirm = {
      message,
      resolve,
      type,
      template,
    };

    return promise;
  });

  confirm = action((message, {
    alwaysResolve = true,
    type = InteractionLevel.default,
    template = ConfirmTemplates.default,
    component = null,
    componentOptions = {},
  }: {
    alwaysResolve?: boolean,
    type?: InteractionLevel,
    template?: ConfirmTemplates,
    component?: any,
    componentOptions?: SimpleObject,
  } = {}) => {
    let resolve = null;
    let reject = null;

    const promise = (new Promise((onFulfilled, onRejected) => {
      [resolve, reject] = (alwaysResolve ? [
        () => onFulfilled(true),
        () => onFulfilled(false),
      ] : [
        onFulfilled,
        onRejected,
      ]);
    }))
      .then((result: boolean) => {
        this.state.confirm = null;
        return result;
      })
      .catch((err) => {
        this.state.confirm = null;
        throw err;
      });


    this.state.confirm = {
      message,
      resolve,
      reject,
      type,
      template,
      component,
      componentOptions,
    };

    return promise;
  });

  toast = action((message, {
    type = InteractionLevel.default,
    displayTime = 4000,
  }: {
    type?: InteractionLevel,
    displayTime?: number,
  } = {}) => {
    let resolve = null;
    let reject = null;

    const promise = (new Promise((onFulfilled) => {
      [resolve, reject] = ([
        () => onFulfilled(true),
        () => onFulfilled(false),
      ]);
    }))
      .then((result: boolean) => {
        this.state.toast = null;
        return result;
      })
      .catch((err) => {
        this.state.toast = null;
        throw err;
      });


    this.state.toast = {
      message,
      resolve,
      reject,
      type,
      displayTime,
    };

    return promise;
  });
}

const storeIdentifier = 'CoreStore';

export const configureCoreStore = createConfigureStore<typeof CoreStore>(storeIdentifier);
export const useCoreStore = createUseStore(CoreStore, storeIdentifier);
