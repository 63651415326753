import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute grid grid-rows-2 w-full h-full items-end justify-center bg-app-bg" }
const _hoisted_2 = { class: "text-3xl font-bold text-center" }
const _hoisted_3 = { class: "pb-20" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconThreeDots = _resolveComponent("IconThreeDots")!
  const _component_ModalCrashState = _resolveComponent("ModalCrashState")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('layout.components.startup.title')), 1),
        _createVNode(_component_IconThreeDots, { class: "mt-10 mx-auto" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "mx-auto",
          width: "250",
          src: _ctx.mainLogo,
          alt: ""
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createVNode(_component_ModalCrashState)
  ]))
}