import {Language} from '@ui/constants/language';
import {
  isArray,
  isFunction,
  mergeWith,
  values,
} from 'lodash-es';
import {
  LocaleMessages, VueMessageType,
} from 'vue-i18n';
import {
  RouteRecordRaw,
  NavigationGuard,
  NavigationHookAfter,
} from 'vue-router';

export type RegisterRoutesOptions = RouteRecordRaw[]|(() => Promise<RouteRecordRaw[]>)
type registerRouteFn = (
  routes: RouteRecordRaw[]|(() => Promise<RouteRecordRaw[]>),
  opts?: {method?: 'push' | 'unshift', layout?: string},
) => Promise<void>
type registerLayoutFn = (name: string, layout: RouteRecordRaw|(() => Promise<RouteRecordRaw>)) => Promise<void>
type registerTranslationsFn = (messages: LocaleMessages<any>|(() => Promise<LocaleMessages<any>[]>)) => Promise<void>
type registerHookBeforeEachFn = (hook: NavigationGuard) => Promise<void>
type registerHookAfterEachFn = (hook: NavigationHookAfter) => Promise<void>


export interface ModuleSystem extends Object {
  registerRoutes: registerRouteFn,
  registerLayout: registerLayoutFn,
  registerTranslations: registerTranslationsFn,
  registerHookBeforeEach: registerHookBeforeEachFn,
  registerHookAfterEach: registerHookAfterEachFn,
  getRegisteredRoutes(): RouteRecordRaw[],
  getTranslations(): LocaleMessages<VueMessageType>,
  getHooks(): {beforeEach: NavigationGuard[], afterEach: NavigationHookAfter[]},
  locale: string,
}

/**
 * Defines what this module needs
 */
export type CoreRequiredContext = {appId: any}

/**
 * Define what this module provides thru context
 */
export type CoreContext = CoreRequiredContext & {
  defaultLayout: any,
}


type RouterTree = {
  default: RouteRecordRaw,
  [key: string]: RouteRecordRaw,
}

export async function registerModuleSystem(ctx: CoreContext): Promise<ModuleSystem> {
  const getRouterRootRouteConfig = (component, options = {}) => ({
    path: '/',
    component,
    children: [],
    redirect: '/dashboard',
    ...options,
  });
  const routerTree: RouterTree = {
    default: getRouterRootRouteConfig(ctx.defaultLayout),
  };

  const messages = {};

  const hooks = {
    beforeEach: [],
    afterEach: [],
  };


  let locale: Language = (navigator.language || 'cs').substring(0, 2) as Language;

  return {
    async registerTranslations(newMessages) {
      function customizer(oldValue, newValue) {
        if (isArray(oldValue)) {
          return newValue;
        }
      }
      mergeWith(messages, newMessages, customizer);
    },
    async registerRoutes(
      newRoutes,
      {method = 'push', layout = 'default'} = {},
    ) {
      if (isFunction(newRoutes)) {
        (routerTree[layout]['children'][method])(...(await newRoutes()));
      } else {
        (routerTree[layout]['children'][method])(...(newRoutes));
      }
    },
    async registerLayout(name, newLayout) {
      if (isFunction(newLayout)) {
        routerTree[name] = await newLayout();
      } else {
        routerTree[name] = newLayout;
      }
    },
    async registerHookBeforeEach(hook) {
      hooks.beforeEach.push(hook);
    },
    async registerHookAfterEach(hook) {
      hooks.afterEach.push(hook);
    },
    getRegisteredRoutes() {
      return [
        ...values(routerTree),
        {
          path: '/:catchAll(.*)*',
          name: 'NotFound',
          redirect: () => {
            return {
              name: '404',
            };
          },
        },
      ];
    },
    getTranslations() {
      return messages;
    },
    getHooks() {
      return hooks;
    },
    set locale(val: Language) {
      locale = val;
    },
    get locale() {
      return locale;
    },
  };
}
