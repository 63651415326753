import {injectFieldContext} from '@designeo/vue-forms';
import {injectFormInputContext} from '@ui/helpers/form';
import {emitTestEvent} from '@ui/helpers/testEvent';
import {TestEvent} from '@ui/tests/e2e/helpers/testEvents';
import {map, orderBy} from 'lodash-es';
import {
  computed,
  defineComponent,
  h,
  onBeforeMount,
  PropType,
  ref,
} from 'vue';
import {useI18n} from 'vue-i18n';

const useApiFetch = (fetch, {initialFetch, emit}) => {
  const innerResult = ref(null);
  const error = ref(null);
  const isLoading = ref(false);

  const trigger = async () => {
    try {
      isLoading.value = true;
      error.value = null;
      innerResult.value = await fetch({
        /**
         * TODO: remove default paginator
         */
        params: {
          limit: 9999,
        },
      });
      emit('fetched');
    } catch (e) {
      console.error(e);
      error.value = e;
    } finally {
      isLoading.value = false;
    }
  };

  const ensureData = async () => {
    if (innerResult.value !== null) {
      return;
    }

    await trigger();
  };

  onBeforeMount(() => {
    if (!initialFetch) {
      return;
    }

    trigger();
  });

  const result = computed(() => {
    return innerResult.value;
  });

  return {
    error,
    isLoading,
    ensureData,
    trigger,
    result,
  };
};

export const HLApiFetch = defineComponent({
  name: 'HLApiFetch',
  props: {
    fetch: {
      type: Function as PropType<(...args: any) => Promise<any>>,
      required: true,
    },
    initialFetch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['fetched'],
  setup(props, {emit}) {
    const {
      error,
      trigger,
      ensureData,
      result,
      isLoading,
    } = useApiFetch(
      props.fetch,
      {
        initialFetch: props.initialFetch,
        emit,
      },
    );

    return {
      error,
      trigger,
      ensureData,
      result,
      isLoading,
    };
  },
  render() {
    const slotData = {
      error: this.error,
      isLoading: this.isLoading,
      result: this.result,
      trigger: this.trigger,
      ensureData: this.ensureData,
    };

    return map((this.$slots?.default?.(slotData) ?? []), (slot) => h(slot));
  },
});


function mapToSelectOptions (arr, optionsMethod) {
  return map(arr, (resultItem) => {
    return resultItem?.data?.[optionsMethod]?.() ?? resultItem?.[optionsMethod]?.() ?? resultItem;
  });
}

export const HLApiFetchSelectOptions = defineComponent({
  name: 'HLApiFetchSelectOptions',
  props: {
    fetch: {
      type: Function as PropType<(...args: any) => Promise<any>>,
      required: true,
    },
    initialFetch: {
      type: Boolean,
      required: false,
      default: null,
    },
    optionMethod: {
      type: String,
      required: false,
      default: 'toSelectOption',
    },
    optionsMethod: {
      type: String,
      required: false,
      default: 'toSelectOptions',
    },
  },
  emits: ['fetched'],
  setup(props, {emit}) {
    const i18n = useI18n();
    const {getModelValue} = injectFieldContext() ?? {getModelValue: () => null};
    const {field} = injectFormInputContext();

    const {
      error,
      trigger,
      ensureData,
      result,
      isLoading,
    } = useApiFetch(
      async (...args) => {
        try {
          return await props.fetch(...args);
        } finally {
          emitTestEvent(`${TestEvent.selectOptionsFetched}:${field}`);
        }
      },
      {
        initialFetch: props.initialFetch ?? !!getModelValue(),
        emit,
      },
    );

    const options = computed(() => {
      if (isLoading.value) {
        return [{label: i18n.t('common.optionsAreLoading'), id: 'optionsAreLoading', disabled: true}];
      }

      if (error.value) {
        return [{label: i18n.t('common.optionsLoadFailed'), id: 'optionsLoadFailed', disabled: true}];
      }

      if (!result.value) {
        return []; // pre-loading state
      }

      const normalizedResult = result.value?.[props.optionsMethod] ?
        result.value?.[props.optionsMethod]() :
        mapToSelectOptions(result.value, props.optionMethod);


      if (!normalizedResult.length) {
        return [{label: i18n.t('common.noOptions'), id: 'noOptions', disabled: true}];
      }

      return normalizedResult;
    });

    const sortBy = computed(() => {
      if (!options.value.length) {
        return undefined;
      }

      const [firstOption] = options.value;

      if (firstOption.disabled) {
        return undefined;
      }

      if (!firstOption.sortKey) {
        return undefined;
      }

      return (arr) => {
        return orderBy(arr, 'sortKey', firstOption.sortOrder ?? 'desc');
      };
    });

    return {
      error,
      trigger,
      ensureData,
      result,
      options,
      isLoading,
      sortBy,
    };
  },
  render() {
    const slotData = {
      error: this.error,
      isLoading: this.isLoading,
      result: this.result,
      options: this.options,
      trigger: this.trigger,
      ensureData: this.ensureData,
      sortBy: this.sortBy,
    };

    return map((this.$slots?.default?.(slotData) ?? []), (slot) => h(slot));
  },
});
