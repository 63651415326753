
import Button from '@ui/components/button/Button.vue';
import Logo from '@ui/components/Logo.vue';
import PhIcon from '@ui/components/PhIcon.vue';
import {EnvironmentDescriptor} from '@ui/constants/environmentDescriptor';
import {provideAsideContext} from '@ui/helpers/aside';
import {Menu as AsideMenuType} from '@ui/helpers/menu';
import {getCurrentAppVersion} from '@ui/helpers/version';
import AsideApplications from '@ui/layouts/components/AsideApplications.vue';
import AsideCollapseToggle from '@ui/layouts/components/AsideCollapseToggle.vue';
import {useAuthStore} from '@ui/modules/Auth/store/AuthStore';
import {useAsideStore} from '@ui/modules/Core/store/AsideStore';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  Ref,
  ref,
} from 'vue';
import {useI18n} from 'vue-i18n';
import AsideMenu from './AsideMenu.vue';
import AsideUser from './AsideUser.vue';

export default defineComponent({
  name: 'Aside',
  components: {
    AsideCollapseToggle,
    AsideApplications,
    AsideUser,
    AsideMenu,
    Logo,
    PhIcon,
    Button,
  },
  props: {
    menu: {
      type: Array as PropType<AsideMenuType>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const asideStore = useAsideStore();
    const authStore = useAuthStore();

    const collapsed = computed({
      get() {
        return asideStore.collapsed.value;
      },
      set(val: boolean) {
        asideStore.setCollapsed(val);
      },
    });
    const collapsedAfterAnimation = ref(collapsed.value);
    const hovered = ref(false);
    const asideRef = ref() as Ref<HTMLDivElement>;
    const menu = computed(() => {
      return props.menu;
    });

    const environment = computed(() => {
      if (!authStore.currentSystem.value) {
        return null;
      }

      switch (authStore.currentSystem.value.environmentDescriptor as EnvironmentDescriptor) {
        case EnvironmentDescriptor.Docker:
          return {
            label: authStore.currentSystem.value.environmentDescriptor,
            classList: ['text-white', 'bg-blue'],
            version: getCurrentAppVersion(),
          };
        case EnvironmentDescriptor.Dev:
          return {
            label: authStore.currentSystem.value.environmentDescriptor,
            classList: ['text-white', 'bg-yellow'],
            version: getCurrentAppVersion(),
          };
        case EnvironmentDescriptor.Test:
          return {
            label: authStore.currentSystem.value.environmentDescriptor,
            classList: ['text-white', 'bg-orange'],
            version: getCurrentAppVersion(),
          };
        case EnvironmentDescriptor.Prod:
          return {
            label: authStore.currentSystem.value.environmentDescriptor,
            classList: ['text-white', 'bg-red'],
            version: getCurrentAppVersion(),
          };
        default: return null;
      }
    });

    const appName = computed(() => {
      if (!authStore.currentSystem.value) {
        return i18n.t('app.appName');
      }

      return authStore.currentSystem.value.displayName;
    });

    provideAsideContext({
      collapsed,
      hovered,
      collapsedAfterAnimation,
      menu,
    });

    const onTransitionEnd = () => {
      collapsedAfterAnimation.value = collapsed.value;
    };

    onMounted(() => {
      asideRef.value.addEventListener('transitionend', onTransitionEnd);
    });

    onBeforeUnmount(() => {
      asideRef.value.removeEventListener('transitionend', onTransitionEnd);
    });

    return {
      appName,
      asideRef,
      collapsed,
      hovered,
      collapsedAfterAnimation,
      environment,
    };
  },
});
