import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_ctx.isAccessible)
    ? (_openBlock(), _createBlock(_component_RouterLink, _normalizeProps({
        key: 0,
        to: _ctx.link.fullPath,
        class: "text-green font-bold",
        [(_ctx.$test.action) || ""]: `grid-cell-link-${_ctx.value}`
      }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.value), 1)
        ]),
        _: 1
      }, 16, ["to"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.value), 1))
}