import {VoucherEmissionType} from '@api/posMiddlewareCore/constants/voucherEmissionType';

export default {
  cs: {
    voucherEmission: {
      detail: {
        title: 'Emise - {name}',
        actions: {
          generateVouchers: {
            title: 'Založit poukázky',
          },
        },
        tabs: {
          vouchers: {
            title: 'Přehled poukázek',
          },
        },
      },
      grid: {
        title: 'Emise poukázek',
        filters: {
          name: 'Název',
          code: 'Kód',
          createdDate: 'Vytvořeno',
          emissionGroupId: 'Skupina',
          typeEnum: {
            allOptions: 'Všechny typy',
          },
        },
        actions: {
          create: {
            title: 'Nová emise',
          },
        },
        advancedFilters: {
          title: 'Filtrovat emise',
          placeholders: {
            name: 'Název',
            code: 'Kód',
            emissionGroupId: 'Všechny skupiny',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvoření nové emise',
        },
        update: {
          title: 'Editace emise',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
          type: 'Vyberte typ poukázky',
          amount: 'Zadejte hodnotu',
          issuer: 'Zadejte vydavatele',
          emissionGroupId: 'Vybertu skupinu emise',
          validFrom: 'Zadejte platnost od',
          validToUseTill: 'Zadejte platnost do',
          technicalArticleNumber: 'Zadejte ID artiklu',
          generateFromNumber: 'Zadejte rozsah od',
          generateQuantity: 'Zadejte počet ks',
          promotionCode: 'Zadejte název promoce',
          maxUsageCount: 'Zadejte max. počet použití',
        },
        common: {
          sections: {
            detail: 'Detail emise',
            configuration: 'Konfigurace emise',
          },
        },
      },
      fields: {
        name: 'Název',
        code: 'Kód',
        type: 'Typ poukázky',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Sleva absolutní',
          [VoucherEmissionType.discountPercentage]: 'Sleva procentuální',
        },
        amount: 'Hodnota',
        issuer: 'Vydavatel',
        emissionGroupId: 'Skupina emise',
        validFrom: 'Platnost od',
        validToUseTill: 'Platnost do',
        technicalArticleNumber: 'Číslo technického artiklu',
        generateFromNumber: 'Rozsah od',
        generateQuantity: 'Počet ks',
        configuration: 'Maska',
        emissionGroup: 'Skupina',
        editedDate: 'Datum editace',
        createdDate: 'Datum vytvoření',
        promotionCode: 'Promoce',
        example: 'Příklad',
        edited: 'Upraveno',
        created: 'Vytvořeno',
        maxUsageCount: 'Max. počet použití jednotlivých poukázek',
        maxUsageCountEnum: {
          withoutRestrictions: 'Bez omezení',
        },
      },
    },
  },
};
