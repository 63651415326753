import {Condition} from '@api/posMiddlewareCore/constants/condition';
import {PointOfSaleVersionUpdateType} from '@api/posMiddlewareCore/constants/pointOfSaleVersionUpdateType';

export default {
  cs: {
    pointOfSaleVersion: {
      form: {
        placeholders: {
          version: 'Vybrete verzi',
          type: 'Vyberte typ',
          condition: 'Vyberte podmínku',
        },
      },
      actions: {
        cancelUpdate: {
          title: 'Zrušit aktualizaci',
          confirm: 'Opravdu si přejete zrušit aktualizaci?',
        },
      },
      fields: {
        posVersion: 'Verze',
        created: 'Založeno',
        createdDate: 'Datum založení',
        createdByName: 'Založil',
        latestStatusType: 'Poslední stav',
        latestStatusCreatedAt: 'Datum posledního stavu',
        statusHistory: 'Historie',
        latestStatusDescription: '{createdAt} - {type}',
        cancelled: 'Zrušeno',
        canceledDate: 'Datum zrušení',
        canceledByName: 'Zrušil',
        version: 'Verze',
        validFrom: 'Platné od',
        type: 'Typ',
        typeEnum: {
          [PointOfSaleVersionUpdateType.immediate]: 'Bez souhlasu obsluhy',
          [PointOfSaleVersionUpdateType.onDemand]: 'Vyžaduje souhlas obsluhy',
        },
        condition: 'Podmínka',
        conditionEnum: {
          [Condition.financialCloseDayFinished]: 'Dokončení finanční uzávěrky',
          [Condition.none]: 'Žádná',
        },
      },
    },
  },
};
