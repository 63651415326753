import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-0 left-0 opacity-0 w-screen h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderPulse = _resolveComponent("LoaderPulse")!
  const _component_TransitionWrapper = _resolveComponent("TransitionWrapper")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#app-loader" }, [
    (_ctx.loaderActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_TransitionWrapper, {
      enterTime: 300,
      leaveTime: 200,
      startDelay: 300,
      isVisible: _ctx.loaderActive
    }, {
      default: _withCtx(({entering, leaving, entered}) => [
        _createElementVNode("div", {
          class: _normalizeClass(["fixed top-0 left-0 h-screen w-screen flex items-center justify-center bg-gray", [
            'opacity-0',
            `bg-opacity-${_ctx.loaderOpacity}`,
            {
              ['ease-out duration-300']: entering,
              ['ease-in duration-200 pointer-events-none']: leaving,
              ['opacity-100']: (entered || entering) && !leaving,
            }
          ]]),
          style: _normalizeStyle({
            zIndex: _ctx.ZLayer.spinner
          })
        }, [
          _createVNode(_component_LoaderPulse, { class: "text-white" })
        ], 6)
      ]),
      _: 1
    }, 8, ["isVisible"])
  ]))
}