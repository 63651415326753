
import {
  defineComponent,
  nextTick,
  ref,
  watch,
} from 'vue';

export default defineComponent({
  name: 'Theme',
  setup() {
    const styleRef = ref<HTMLStyleElement>(null);

    watch(
      () => null,
      async (newVal, oldVal) => {
        await nextTick();
        styleRef.value.innerHTML = `:root {}`;
      }, {immediate: true});

    return {
      styleRef,
    };
  },
});
