import {VoucherEventOperation} from '@api/posMiddlewareCore/constants/voucherEventOperation';

export default {
  cs: {
    voucherEvent: {
      fields: {
        createdDate: 'Datum',
        operation: 'Akce',
        operationEnum: {
          [VoucherEventOperation.creation]: 'Vygenerování poukázky',
          [VoucherEventOperation.cancellation]: 'Storno poukázky',
          [VoucherEventOperation.redemption]: 'Uplatnění poukázky',
        },
        place: 'Místo',
        customer: 'Zákazník',
        cashier: 'Pokladní',
        updatedByName: 'Uživatel',
        state: 'Stav',
      },
    },
  },
};

