
import AsyncButton from '@ui/components/button/Button.vue';
import Modal from '@ui/components/modal/Modal.vue';
import {ZLayer} from '@ui/constants/zLayer';
import {
  computed,
  defineComponent,
} from 'vue';
import {useCrashStore} from '../store/CrashStore';

export default defineComponent({
  name: 'ModalCrashState',
  components: {
    AsyncButton,
    Modal,
  },
  setup() {
    const crashStore = useCrashStore();

    const open = computed(() => {
      return crashStore.active.value;
    });

    const refresh = () => {
      window.location.reload();
    };

    return {
      open,
      refresh,
      ZLayer,
    };
  },
});
