import {useClickOutMixin} from '@designeo/vue-helpers';
import {
  computePosition,
  flip,
  offset,
  shift,
} from '@floating-ui/dom';
import {ComputePositionConfig} from '@floating-ui/dom/src/types';
import {provideDropdownContext} from '@ui/helpers/dropdown';
import {map} from 'lodash-es';
import {
  computed,
  defineComponent,
  h,
  nextTick,
  onBeforeUnmount,
  PropType,
  ref,
} from 'vue';

export const useDropdown = ({
  placement = 'bottom-start',
  middleware = [offset(3), flip(), shift({padding: 5})],
}: Partial<ComputePositionConfig> = {}) => {
  const triggerRef = ref<HTMLDivElement>(null);
  const dropdownRef = ref<HTMLDivElement>(null);
  const dropdownCleanup = ref(null);

  const createDropdown = async () => {
    const {x, y} = await computePosition(
      triggerRef.value,
      dropdownRef.value,
      {
        placement,
        middleware,
      },
    );

    Object.assign(dropdownRef.value.style, {
      top: `${y}px`,
      left: `${x}px`,
      zIndex: 1,
    });
  };

  const destroyDropdown = () => {
    dropdownCleanup.value?.();
    dropdownCleanup.value = null;
  };

  onBeforeUnmount(() => {
    destroyDropdown();
  });

  return {
    triggerRef,
    dropdownRef,
    createDropdown,
    destroyDropdown,
    dropdownCleanup,
  };
};

export const HLDropdown = defineComponent({
  name: 'HLDropdown',
  props: {
    placement: {
      type: String as PropType<ComputePositionConfig['placement']>,
      required: false,
      default: undefined,
    },
    middleware: {
      type: Object as PropType<ComputePositionConfig['middleware']>,
      required: false,
      default: undefined,
    },
    clickOut: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const isActive = ref(false);
    const dropdown = useDropdown({
      placement: props.placement,
      middleware: props.middleware,
    });

    const {registerClickOut, unregisterClickOut} = useClickOutMixin(async () => {
      await hide({delay: 0});
    });

    let hideTimeout;
    let showTimeout;

    const show = async ({delay = 0} = {}) => {
      clearTimeout(hideTimeout);
      clearTimeout(showTimeout);

      showTimeout = setTimeout(async () => {
        if (dropdown.dropdownCleanup.value) {
          return;
        }
        isActive.value = true;
        await nextTick();

        await dropdown.createDropdown();

        if (props.clickOut) {
          registerClickOut(dropdown.triggerRef.value);
        }
      }, delay);
    };

    const hide = async ({delay = 200} = {}) => {
      clearTimeout(hideTimeout);
      clearTimeout(showTimeout);

      hideTimeout = setTimeout(async () => {
        isActive.value = false;
        await dropdown.destroyDropdown();

        if (props.clickOut) {
          unregisterClickOut();
        }
      }, delay);
    };

    const toggle = async () => {
      if (isActive.value) {
        await hide({delay: 0});
      } else {
        await show();
      }
    };

    if (props.clickOut) {
      onBeforeUnmount(function() {
        unregisterClickOut();
      });
    }

    provideDropdownContext({
      isActive,
      show,
      hide,
      toggle,
    });

    return {
      ...dropdown,
      isActive,
      show,
      hide,
      toggle,
    };
  },
  render() {
    const slotData = {
      triggerRef: computed({
        get: () => {
          return this.triggerRef;
        },
        set: (val) => {
          this.triggerRef = val;
        },
      }),
      dropdownRef: computed({
        get: () => {
          return this.dropdownRef;
        },
        set: (val) => {
          this.dropdownRef = val;
        },
      }),
      isActive: computed({
        get: () => {
          return this.isActive;
        },
        set: async (val) => {
          this.isActive = val;
        },
      }),
      show: this.show,
      hide: this.hide,
      toggle: this.toggle,
    };

    return map((this.$slots?.default?.(slotData) ?? []), (slot) => h(slot));
  },
});
