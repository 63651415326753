import {
  createConfigureStore,
  createUseStore,
  getter,
  action,
} from '@designeo/vue-helpers';
import {
  createSessionStoragePersistentVariableWithMigration,
  withMigrations,
} from '@ui/helpers/persist';
import {PersistentStore} from '@ui/helpers/store';

export interface IAsideStore {
  collapsed: boolean,
}

const createInitState = (data?: Partial<IAsideStore>): IAsideStore => ({
  collapsed: false,
});

export class AsideStore extends PersistentStore<IAsideStore> {
  constructor() {
    super(
      createInitState(),
      withMigrations<IAsideStore>([])(createSessionStoragePersistentVariableWithMigration('asideStore')),
    );
  }

  setCollapsed = action((collapsed: boolean) => {
    this.state.collapsed = collapsed;
  });

  collapsed = getter(() => {
    return this.state.collapsed;
  });
}

const storeIdentifier = 'AsideStore';

export const configureAsideStore = createConfigureStore<typeof AsideStore>(storeIdentifier);
export const useAsideStore = createUseStore(AsideStore, storeIdentifier);
