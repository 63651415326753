import {App} from 'vue';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type PointOfSaleTransactionRequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  app: App<any>,
}

/**
 * Define what this module provides thru context
 */
export type CoreContext = PointOfSaleTransactionRequiredContext & {
}

export async function registerPointOfSaleTransactionModule(ctx: PointOfSaleTransactionRequiredContext) {
  await ctx.registerTranslations(translations);

  return {};
}
