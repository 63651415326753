import {App} from 'vue';
import {
  createRouter,
  Router,
  createMemoryHistory,
  createWebHistory,
} from 'vue-router';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = import('./modules').ModuleSystem & {
  app: App<any>,
}

export type RegisterRouterContext = {
  router: Router,
}

export async function registerRouter(ctx: CoreRequiredContext) {
  const router = createRouter({
    history: typeof window !== 'undefined' ? createWebHistory() : createMemoryHistory(),
    routes: ctx.getRegisteredRoutes(),
  });

  for (const beforeEachHook of ctx.getHooks()?.beforeEach ?? []) {
    router.beforeEach(beforeEachHook);
  }

  for (const afterEachHook of ctx.getHooks()?.afterEach ?? []) {
    router.afterEach(afterEachHook);
  }

  return {
    router,
  };
}
