
import {injectAsideContext} from '@ui/helpers/aside';
import AsideMenuDropdown from '@ui/layouts/components/AsideMenuDropdown.vue';
import {
  defineComponent,
} from 'vue';
import AsideMenuGroup from './AsideMenuGroup.vue';
import AsideMenuItem from './AsideMenuItem.vue';

export default defineComponent({
  name: 'AsideMenu',
  components: {AsideMenuDropdown, AsideMenuGroup, AsideMenuItem},
  setup() {
    const {menu} = injectAsideContext();

    return {
      menu,
    };
  },
});
